import {
  FiltersValues,
  Point
} from 'src/views/avl/dispatching/trafficMap/components/Tracking/types';
import { AutocompleteConfig } from 'src/components/Form/FormAutocomplete/types';
import {
  IDLE_THRESHOLD_IN_MINUTES,
  STADIUM_VEHICLES_AUTOCOMPLETE_NAME,
  STADIUM_VEHICLES_AUTOCOMPLETE_PATH
} from 'src/views/avl/dispatching/trafficStadium/components/VehicleCard/const';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import moment from 'moment';
import { DETAILED_TIME_FORMAT } from 'src/config';
import { colors } from '@material-ui/core';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as VehicleFirstPoint } from 'src/assets/icons/vehicleFirstPoint.svg';
import { ReactComponent as VehicleLastPoint } from 'src/assets/icons/vehicleLastPoint.svg';
import { ReactComponent as VehiclePoint } from 'src/assets/icons/vehiclePoint.svg';
import { StandVehicleIcon } from 'src/views/avl/dispatching/trafficMap/components/RealTime/VehicleIcon';

export const TIME_TO_DEFAULT_VALUE = '23:59';
export const TIME_FROM_DEFAULT_VALUE = '00:00';
export const MAX_TIME_RANGE_IN_DAYS = 7;

export const initialValues: FiltersValues = {
  counteragentId: '',
  vehicle: null,
  dateFrom: null,
  dateTo: null,
  timeFrom: null,
  timeTo: null
};

export const getVehicleConfig = ({
  counteragentId
}: {
  counteragentId?: number | string;
}): AutocompleteConfig => ({
  reducerPath: STADIUM_VEHICLES_AUTOCOMPLETE_PATH,
  reducerName: STADIUM_VEHICLES_AUTOCOMPLETE_NAME,
  prohibitInitialLoad: !counteragentId,
  optionsApiUrl: counteragentId
    ? `${COUNTERAGENTS}/${counteragentId}/vehicles-autocomplete`
    : ''
});

export const LOADING_KEY = '@TRAFFIC_MAP/GET_VEHICLE_TRACK';

const getIcons = (
  standIconColor: string
): Record<string, L.DivIconOptions> => ({
  STARTING: {
    html: ReactDOMServer.renderToStaticMarkup(<VehicleFirstPoint />),
    iconAnchor: [15, 15],
    iconSize: [30, 30]
  },
  ENDING: {
    html: ReactDOMServer.renderToStaticMarkup(<VehicleLastPoint />),
    iconAnchor: [15, 15],
    iconSize: [30, 30]
  },
  INTERMEDIATE_MOVING: {
    html: ReactDOMServer.renderToStaticMarkup(<VehiclePoint />),
    iconAnchor: [7.5, 7.5],
    iconSize: [15, 15]
  },
  INTERMEDIATE_STAYING: {
    html: ReactDOMServer.renderToStaticMarkup(
      <StandVehicleIcon color={standIconColor} stroke="#2A64C5" />
    ),
    iconAnchor: [13, 13.5],
    iconSize: [26, 27]
  }
});

export const getVehicleIcon = ({
  stayOnPlace,
  isStartingPoint,
  isEndingPoint,
  isMoving
}: Partial<Point> & {
  isStartingPoint: boolean;
  isEndingPoint: boolean;
  isMoving: boolean;
}) => {
  const isIdle =
    moment(stayOnPlace, DETAILED_TIME_FORMAT).minutes() >
    IDLE_THRESHOLD_IN_MINUTES;
  const standIconColor = isIdle ? colors.red[500] : '#377ef6';

  const icons = getIcons(standIconColor);
  let icon: L.DivIconOptions;

  if (isStartingPoint) {
    icon = icons.STARTING;
  } else if (isEndingPoint) {
    icon = icons.ENDING;
  } else if (isMoving) {
    icon = icons.INTERMEDIATE_MOVING;
  } else {
    icon = icons.INTERMEDIATE_STAYING;
  }

  return L.divIcon({
    ...icon,
    className: ''
  });
};
