import { Row } from 'src/components/Tables/types';
import {
  changeStatusColumn,
  formatDate
} from 'src/components/Tables/TableWithPagination/utils';
import {
  STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
  STATE_CHANGES,
  EQUIPMENT,
  STATES,
  STATE_CHANGES_BARCODE_AUTOCOMPLETE,
  STATE_CHANGES_IMEI_AUTOCOMPLETE,
  STATE_CHANGES_SPARE_PARTS_AUTOCOMPLETE,
  STATE_CHANGES_ADDRESS_AUTOCOMPLETE,
  STATE_CHANGES_NOTES_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import { STATUS_CONFIG } from 'src/constants';
import { StateChangeStatusEnum } from '../const';
import StateChangeSwitchColumn from './StateChangeSwitchColumn';

import {
  BARCODE_AUTOCOMPLETE_NAME,
  BARCODE_AUTOCOMPLETE_PATH,
  SPARE_PARTS_AUTOCOMPLETE_NAME,
  SPARE_PARTS_AUTOCOMPLETE_PATH
} from '../../reports/const';
import StateTypeColumn from '../../states/StatesListView/StateTypeColumn';
import {
  TRACKING_ADDRESS_AUTOCOMPLETE_NAME,
  TRACKING_ADDRESS_AUTOCOMPLETE_PATH,
  TRACKING_IMEI_AUTOCOMPLETE_NAME,
  TRACKING_IMEI_AUTOCOMPLETE_PATH
} from '../../tracking/TrackingListView/const';
import { createCounteragentFilter } from '../../const';

export const STATE_CHANGE_TABLE_PATH = 'asset.stateChange.table';
export const STATE_CHANGE_TABLE_NAME = 'STATE_CHANGE';

export const TABLE_CONFIG_BASE = {
  reducerName: STATE_CHANGE_TABLE_NAME,
  reducerPath: STATE_CHANGE_TABLE_PATH,
  apiUrl: STATE_CHANGES
};

export const getColumns = (
  isShowEditAllowed: boolean,
  onChangeStatus: (row: Row) => Promise<void>
) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Дата зміни стану',
    value: 'stateChangeDate',
    sortable: true,
    width: '15%',
    formatValue: (row: Row) => formatDate(row.stateChangeDate)
  },
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Попередній стан',
    value: 'previousStateId',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => (
      <StateTypeColumn id={row.previousStateId} name={row.previousState} />
    ),
    formatValue: (row: Row) => row.previousState
  },
  {
    label: 'Новий стан',
    value: 'newStateId',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => (
      <StateTypeColumn id={row.newStateId} name={row.newState} />
    ),
    formatValue: (row: Row) => row.newState
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentFullName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Адреса',
    value: 'counteragentAddress',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Хто змінив стан',
    value: 'employeeName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус документа',
    value: 'statusId',
    sortable: true,
    width: '15%',
    changeValue: changeStatusColumn,
    formatValue: (row: Row) => STATUS_CONFIG[row.status.toLowerCase()].label
  },
  {
    label: '',
    value: '',
    sortable: false,
    changeValue: (row: Row) =>
      isShowEditAllowed ? (
        <StateChangeSwitchColumn data={row} onChange={onChangeStatus} />
      ) : null,
    width: '15%'
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME =
  'STATE_CHANGE__INSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE';

export const STATE_CHANGES_NOTES_AUTOCOMPLETE_NAME =
  'STATE_CHANGES_NOTES_AUTOCOMPLETE_NAME';

export const getFilters = () => [
  {
    type: FilterType.Date,
    name: 'stateChangeDate',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    },
    width: 220
  },
  {
    name: 'barcode',
    value: 'name',
    label: 'Штрихкод',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: BARCODE_AUTOCOMPLETE_NAME,
      reducerPath: BARCODE_AUTOCOMPLETE_PATH,
      optionsApiUrl: STATE_CHANGES_BARCODE_AUTOCOMPLETE
    },
    width: 220
  },
  {
    name: 'name',
    label: 'Назва',
    optionsURL: EQUIPMENT,
    value: 'name',
    width: 220
  },
  {
    name: 'imei',
    value: 'name',
    label: 'IMEI',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: TRACKING_IMEI_AUTOCOMPLETE_NAME,
      reducerPath: TRACKING_IMEI_AUTOCOMPLETE_PATH,
      optionsApiUrl: STATE_CHANGES_IMEI_AUTOCOMPLETE
    },
    width: 220
  },
  {
    name: 'previousStateId',
    label: 'Попередній стан',
    optionsURL: STATES,
    width: 220
  },
  {
    name: 'newStateId',
    label: 'Новий стан',
    optionsURL: STATES,
    width: 220
  },
  {
    name: 'spareParts',
    value: 'name',
    label: 'Запчастини',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: SPARE_PARTS_AUTOCOMPLETE_NAME,
      reducerPath: SPARE_PARTS_AUTOCOMPLETE_PATH,
      optionsApiUrl: STATE_CHANGES_SPARE_PARTS_AUTOCOMPLETE
    },
    width: 220
  },
  createCounteragentFilter({ width: 220 }),
  {
    name: 'counteragentAddress',
    value: 'name',
    label: 'Адреса',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: TRACKING_ADDRESS_AUTOCOMPLETE_NAME,
      reducerPath: TRACKING_ADDRESS_AUTOCOMPLETE_PATH,
      optionsApiUrl: STATE_CHANGES_ADDRESS_AUTOCOMPLETE
    },
    width: 220
  },
  {
    name: 'employeeId',
    label: 'Хто змінив стан',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.stateChange.responsibleEmployeeAutocomplete',
      optionsApiUrl: STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
      value: 'id'
    },
    width: 220
  },
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: StateChangeStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: StateChangeStatusEnum.Draft
      }
    ],
    width: 220
  },
  {
    name: 'notes',
    label: 'Примітка',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: STATE_CHANGES_NOTES_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.stateChange.notesAutocomplete',
      optionsApiUrl: STATE_CHANGES_NOTES_AUTOCOMPLETE
    },
    width: 220,
    value: 'name'
  }
];

export const deleteWarningAppendix =
  'Все демонтоване обладнання буде повернене у моніторинг';
