import { Row } from 'src/components/Tables/types';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import { utcToLocal } from 'src/utils/date';

export const normalizeBreakdownData = (data: Row) => {
  const counteragentID = data?.counteragentId;
  return data?.map((item: Row) => ({
    breakdown: item.breakdownTypeName,
    equipment: item.equipmentDetails.name,
    breakdownTypeId: item.breakdownTypeId,
    state: item.equipmentDetails.state,
    id: item.installationEquipmentId,
    barCode: item.equipmentDetails.barcode,
    name: item.equipmentDetails.name,
    productType: item.equipmentDetails.productType,
    installationVehicleId: item.id,
    installationCountragentId: counteragentID,
    equipmentStatusName: item.equipmentDetails.state,
    assetId: item.assetId
  }));
};

export const normalizeBreakdownFormData = (data: Row) => {
  const parseToAutocompleteValue = (name?: string, id = 0) =>
    name ? { name, id } : null;

  return {
    documentId: `${data.documentNumber}`,
    installDate: utcToLocal(data.registrationDate, DATEPICKER_DATE_FORMAT),
    installingEmployee: data.reportingEmployeeName,
    acceptingEmployee: parseToAutocompleteValue(
      data.registrationEmployeeName,
      data.registeredByEmployeeId
    ),
    equipmentType: data.equipmentGroupId,
    counteragent: parseToAutocompleteValue(data.counteragentName),
    counteragentId: data.counteragentId,
    vehicleId: data.vehicleId,
    address: data.counteragentAddress,
    vehicleNumbers: parseToAutocompleteValue(data.licensePlate),
    vehicleModelName: data.vehicleModelName,
    vehicleType: data.vehicleCategoryName,
    notes: data.notes,
    breakdownItems: data.breakdownItems,
    responsiblePerson: {
      id: data.responsiblePersonId,
      name: data.responsiblePersonFullName
    }
  };
};
