import { createReducer, ActionType } from 'typesafe-actions';
import { TableWithPaginationState } from 'src/components/Tables/TableWithPagination/types';
import * as actions from './actions';
import { getSelection } from '../utils';

type TableActions = ActionType<typeof actions>;

export const initialState: TableWithPaginationState = {
  list: {
    results: [],
    currentPage: 1,
    pageCount: 1,
    rowCount: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0
  },
  allResults: [],
  sorters: [],
  filters: [],
  selection: {
    selectAll: false,
    included: [],
    excluded: []
  },
  appliedFilters: {}
};

const tableWithPaginationReducers = createReducer<
  TableWithPaginationState,
  TableActions
>(initialState)
  .handleAction(actions.getDataSuccess, (state, { payload }) => ({
    ...state,
    ...(payload.resetSelection && {
      selection: {
        selectAll: false,
        included: [],
        excluded: []
      }
    }),
    ...payload
  }))
  .handleAction(actions.getFullData.success, (state, { payload }) => ({
    ...state,
    allResults: payload
  }))
  .handleAction(actions.setSelected, (state, { payload: { checked, id } }) => {
    const {
      selection,
      list: { rowCount }
    } = state;
    const newSelection = getSelection(checked, id, selection, rowCount);

    return {
      ...state,
      selection: newSelection
    };
  })
  .handleAction(actions.setSelectAll, (state, { payload }) => ({
    ...state,
    selection: {
      selectAll: payload,
      included: [],
      excluded: []
    }
  }))
  .handleAction(actions.getFilterOptionsSuccess, (state, { payload }) => ({
    ...state,
    appliedFilters: {
      ...state.appliedFilters,
      [payload.filterName]: payload.options
    }
  }))
  .handleAction(actions.tableUnmount, state => ({
    ...initialState
  }))
  .handleAction(actions.clearTableWithPaginationState, () => initialState);

export default tableWithPaginationReducers;
