import { get } from 'lodash';
import { RootState } from 'typesafe-actions';
import { FilterOptions } from '../types';

export const selectAutocompleteOptions = <T extends object = {}>(
  reducerPath: string,
  filterOptions?: FilterOptions<T>
) => (state: RootState): AutocompleteOption<T>[] => {
  const allOptions = get(state, `ui.${reducerPath}.options`, []);

  const options = allOptions.filter(
    (option: AutocompleteOption<{ shortName: string }>) =>
      !!option.name || !!option.shortName
  );

  return filterOptions ? filterOptions(options) : options;
};

export const selectLoading = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.loading`, false);
