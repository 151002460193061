import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { InstallationDetailsState } from '../../InstallationDetailsView/types';

type InstallationDetailsActions = ActionType<typeof actions>;

export const initialInstallationDetailsState: InstallationDetailsState = {
  id: 0,
  documentNumber: 0,
  installationDate: null,
  installingEmployeeName: '',
  installingEmployeeFullName: '',
  acceptingEmployeeName: '',
  acceptingEmployeeFullName: '',
  equipmentGroupName: 'Мобільне обладнання',
  counteragentName: '',
  equipmentGroupId: 2,
  licensePlate: '',
  vehicleModelName: '',
  counteragentAddress: '',
  employeeName: '',
  employeeFullName: '',
  notes: '',
  installationStatusId: StatusEnum.Fixed,
  equipments: [],
  counteragentHolderName: '',
  counteragentHolderAddress: '',
  vehicleCategoryName: '',
  createdAtUtc: null,
  modifiedAtUtc: null
};

const installationEmailDataReducers = createReducer<
  InstallationDetailsState,
  InstallationDetailsActions
>(initialInstallationDetailsState).handleAction(
  actions.getInstallationRowData.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default installationEmailDataReducers;
