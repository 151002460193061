import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { InstallationDetailsState } from '../types';

type InstallationDetailsActions = ActionType<typeof actions>;

const initialState: InstallationDetailsState = {
  id: 0,
  documentNumber: 0,
  installationDate: null,
  installingEmployeeName: '',
  installingEmployeeFullName: '',
  equipmentGroupName: '',
  acceptingEmployeeName: '',
  acceptingEmployeeFullName: '',
  counteragentName: '',
  counteragentHolderName: '',
  counteragentHolderAddress: '',
  equipmentGroupId: 2,
  licensePlate: '',
  vehicleModelName: '',
  vehicleCategoryName: '',
  counteragentAddress: '',
  employeeName: '',
  employeeFullName: '',
  notes: '',
  installationStatusId: StatusEnum.Fixed,
  createdAtUtc: null,
  modifiedAtUtc: null,
  equipments: [
    {
      id: 0,
      name: '',
      model: '',
      manufacturer: '',
      productType: '',
      imei: '',
      state: 'Norm',
      equipmentStateId: 0,
      equipmentStateName: 'Норма',
      barcode: '0',
      supplements: []
    }
  ]
};

const installationDetailsReducers = createReducer<
  InstallationDetailsState,
  InstallationDetailsActions
>(initialState).handleAction(
  actions.getInstallation.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default installationDetailsReducers;
