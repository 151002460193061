import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { UninstallationPayload } from './types';

const initialState: UninstallationPayload = {
  id: 0,
  documentNumber: '',
  uninstallationDate: '',
  uninstallingEmployeeName: '',
  uninstallingEmployeeId: 0,
  notes: '',
  status: StatusEnum.Fixed,
  mobileEquipment: [],
  stationaryEquipment: [],
  inspectorEquipments: [],
  acceptingEmployeeName: '',
  counteragentHolderName: '',
  counteragentHolderAddress: '',
  counteragentHolderId: 0,
  acceptingEmployeeId: 0,
  acceptingEmployeeFullName: '',
  uninstallingEmployeeFullName: '',
  uninstalledEquipmentCount: 0,
  statusId: 0,
  createdAtUtc: null,
  modifiedAtUtc: null,
  counteragentHolderFullName: ''
};

const uninstallationDetailsReducers = createReducer<
  UninstallationPayload,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getUninstallation.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default uninstallationDetailsReducers;
