import { Row } from 'src/components/Tables/types';
import { INSPECTION_DOCUMENTS } from 'src/config/clearingUrls';
import { CARRIERS, VEHICLES_AUTOCOMPLETE } from 'src/config/avlUrls';
import { INSPECTION_DOCUMENTS_URL } from 'src/routes/clearingRoutes';
import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import NewTabLink from 'src/components/NewTabLink';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import { crudActions } from '../const';
import InspectionDocumentLabel from './InspectionDocumentLabel';
import { InspectionDocumentStatus } from '../types';

export const columns = [
  {
    label: 'Дата документа',
    value: 'inspectionDate',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) =>
      convertUtcToLocal(row.inspectionDate, DATE_FORMAT_SLASH)
  },
  {
    label: 'Назва документа',
    value: 'documentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Перевізник',
    value: 'counteragentId',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => row.counteragentName
  },
  {
    label: 'Реєстраційний номер ТЗ',
    value: 'vehicleId',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => row.licensePlate
  },
  {
    label: 'Параметри КПЕ',
    value: 'kpiParametersId',
    sortable: false,
    width: '15%',
    changeValue: (row: Row) => (
      <NewTabLink
        to={`/clearing/kpiParameters/${row.kpiParametersId}/details`}
        stopPropagation
      >
        Переглянути
      </NewTabLink>
    )
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <InspectionDocumentLabel status={row.status} />
  }
];

export const INSPECTION_DOCUMENTS_TABLE_PATH =
  'clearing.inspectionDocuments.table';
export const INSPECTION_DOCUMENTS_TABLE_NAME = 'INSPECTION_DOCUMENTS';

export const VEHICLES_AUTOCOMPLETE_NAME = 'VEHICLES_AUTOCOMPLETE_NAME';
export const VEHICLES_AUTOCOMPLETE_PATH =
  'clearing.inspectionDocuments.listVehiclesAutocomplete';

export const VEHICLES_AUTOCOMPLETE_CONFIG = {
  reducerName: VEHICLES_AUTOCOMPLETE_NAME,
  reducerPath: VEHICLES_AUTOCOMPLETE_PATH,
  optionsApiUrl: VEHICLES_AUTOCOMPLETE
};

const filters = [
  {
    type: FilterType.Date,
    name: 'inspectionDate',
    label: 'Дата'
  },
  {
    name: 'counteragentId',
    label: 'Перевізник',
    optionsURL: CARRIERS
  },
  {
    name: 'vehicleId',
    label: 'ТЗ',
    type: FilterType.Autocomplete,
    autocompleteConfig: VEHICLES_AUTOCOMPLETE_CONFIG
  }
];

export const config = {
  reducerName: INSPECTION_DOCUMENTS_TABLE_NAME,
  reducerPath: INSPECTION_DOCUMENTS_TABLE_PATH,
  apiUrl: INSPECTION_DOCUMENTS,
  routeUrl: INSPECTION_DOCUMENTS_URL,
  isRowClickEnabled: true,
  filters,
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: (row: Row) => row.status !== InspectionDocumentStatus.Billed,
      action: crudActions.update
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) => row.canDelete,
      action: crudActions.delete
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  }
};
