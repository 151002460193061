import { PdfGenerator, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DETAILED_DATE_TIME_FORMAT } from 'src/config';
import { transferDocumentPdf, transferColumnsPdf } from '../const';
import { TransferDetailsPayload } from '../types';

export function generatePdfOfTransfer(
  data: TransferDetailsPayload
): PdfGenerator {
  const transferData = {
    ...data,
    modifiedAtUtc: utcToLocal(data.modifiedAtUtc, DETAILED_DATE_TIME_FORMAT)
  };

  const {
    documentNumber,
    equipment,
    dateTransfer,
    acceptingPerson
  } = transferData;

  const dateTitle = utcToLocal(dateTransfer, DATE_FORMAT_FULL);
  const dateSubject = utcToLocal(dateTransfer, DATE_FORMAT_FULL);

  const title = `Переміщення №${documentNumber} ${acceptingPerson} ${dateTitle}`;
  const subject = `Акт переміщення № ${documentNumber} від ${dateSubject}`;

  return new PdfGenerator(title, 13, { orientation: 'landscape' })
    .setHeader(subject, true)
    .setTable(transferDocumentPdf, transferData, TypeTable.InfoTable)
    .setHeader('Переміщене обладнання', false)
    .setTable(transferColumnsPdf, equipment, TypeTable.ListTable);
}
