import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.installation,
    url: '/asset/installation'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'installation'
  },
  ['download', 'resendDocument', 'installationAllowEditing']
);

export enum InstallationStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const INSTALLATION_STEPS = [
  'Встановлення',
  'Обладнання',
  'Перевірка статусу'
];

export const installationDocumentPdf = [
  {
    label: 'Дата та час збереження документа',
    value: 'modifiedAtUtc'
  },
  {
    label: 'Cклад',
    value: 'counteragentHolderName'
  },
  {
    label: 'Адреса складу',
    value: 'counteragentHolderAddress'
  },
  {
    label: 'Підрозділ монтажу',
    value: 'counteragentName'
  },
  {
    label: 'Адреса підрозділу монтажу',
    value: 'counteragentAddress'
  },
  {
    label: 'Виконавець',
    value: 'installingEmployeeFullName'
  },
  {
    label: 'Прийняв роботу',
    value: 'acceptingEmployeeFullName'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupName'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategoryName'
  },
  {
    label: 'Номерний знак ТЗ',
    value: 'licensePlate'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModelName'
  },
  {
    label: 'Працівник підрозділу монтажу - відповідальна особа',
    value: 'employeeFullName'
  },
  {
    label: 'Представник перевізника',
    value: ''
  }
];

export const installationColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'name'
  },
  {
    label: 'Модель',
    value: 'model'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'ІМЕІ',
    value: 'imei'
  },
  {
    label: 'Запчастини',
    value: 'spareParts'
  },
  {
    label: 'Стан',
    value: 'equipmentStateName'
  }
];

export const installationFieldsJson = [
  'documentNumber',
  'installationDate',
  'employeeName',
  'employeeFullName',
  'acceptingEmployeeName',
  'acceptingEmployeeFullName',
  'counteragentHolderName',
  'counteragentName',
  'equipmentGroupName',
  'vehicleModelName',
  'counteragentAddress',
  'licensePlate',
  'vehicleModelName',
  'vehicleCategoryName',
  'installingEmployeeName',
  'installingEmployeeFullName',
  'notes',
  'modifiedAtUtc',
  {
    label: 'equipments',
    fields: [
      'name',
      'model',
      'manufacturer',
      'barcode',
      'imei',
      'equipmentStateName',
      'spareParts'
    ]
  }
];

export const INSTALLATION_ERROR_MESSAGES: Record<string, string> = {
  "It's forbidden to add more than one piece of equipment with Imei to one installation document":
    'Ви вже додали в даний документ обладнання з IMEI',
  forbidden:
    'Стан Виведено не використовується для встановленого обладнання. Даний стан дозволяється тільки для невстановленого обладнання, що знаходиться на складах'
};
