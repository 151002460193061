import { Row } from 'src/components/Tables/types';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import {
  CARRIERS,
  ROUTE_NUMBER_AUTOCOMPLETE,
  VEHICLES_CATEGORIES
} from 'src/config/avlUrls';
import AlertIcon from 'src/components/AlertIcon';
import {
  FilterConfig,
  FilterType
} from 'src/components/Tables/TableWithPagination/types';
import RoutesTenderStatusColumn from './RoutesTenderStatusColumn';
import { PassportStatusEnum, passportStatusMap } from '../const';

export const columns = [
  {
    label: 'Номер',
    value: 'number',
    sortable: true,
    width: '5%'
  },
  {
    label: 'Назва маршруту',
    value: 'name',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategoryName',
    sortable: true,
    width: '7%'
  },
  {
    label: 'Початок дії паспорта',
    value: 'effectiveDate',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Перевізник',
    value: 'counteragentName',
    sortable: false,
    width: '10%'
  },
  {
    label: 'Тариф, грн/км',
    value: 'tariffAmount',
    sortable: true,
    width: '12%'
  },
  {
    label: 'Тендер',
    value: 'tenderStatusId',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => (
      <RoutesTenderStatusColumn
        tenderStatus={row.tenderStatus}
        tenderStatusId={row.tenderStatusId}
      />
    )
  },
  {
    label: 'Статус',
    value: 'statusId',
    sortable: true,
    width: '5%',
    changeValue: (row: Row) =>
      changeStatusColumn({
        ...row,
        status: passportStatusMap[row.statusId as PassportStatusEnum]
      })
  },
  {
    label: '',
    value: '',
    sortable: false,
    width: '5%',
    changeValue: (row: Row) => {
      if (row.isObsolete || row.timetableDepotRequired) {
        const title = row.isObsolete
          ? 'Оновлено дані, перезатвердіть паспорт'
          : 'Оберіть перевізника у розкладах';

        return <AlertIcon title={title} />;
      }

      return <></>;
    }
  }
];

export const ROUTES_TABLE_PATH = 'avl.routes.settings.table';
export const ROUTES_TABLE_NAME = 'ROUTES';

export const ROUTE_NUMBER_AUTOCOMPLETE_NAME = 'ROUTE_NUMBER_AUTOCOMPLETE_NAME';

export const filters: FilterConfig[] = [
  {
    name: 'number',
    label: 'Номер маршруту',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: ROUTE_NUMBER_AUTOCOMPLETE_NAME,
      reducerPath: 'avl.routes.routeNumberAutocomplete',
      optionsApiUrl: ROUTE_NUMBER_AUTOCOMPLETE
    },
    value: 'number'
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Затверджено',
        value: PassportStatusEnum.Approved
      },
      {
        label: 'Чернетка',
        value: PassportStatusEnum.Draft
      }
    ]
  },
  {
    name: 'vehicleCategoryName',
    label: 'Тип ТЗ',
    optionsURL: VEHICLES_CATEGORIES,
    value: 'name'
  },
  {
    name: 'counterAgents',
    label: 'Перевізник',
    optionsURL: CARRIERS,
    value: 'name',
    comparison: 'Contains'
  }
];
