import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from '../hooks/usePrevious';

const NavigationContext = createContext({
  prevLocation: ''
});

export const NavigationProvider = ({ children }: PropsWithChildren<any>) => {
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname) ?? '';
  const value = useMemo(() => ({ prevLocation }), [prevLocation]);

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
