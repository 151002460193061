import {
  BREAKDOWNS,
  BREAKDOWNS_NOTES_AUTOCOMPLETE,
  EQUIPMENT_GROUP_NAMES,
  REGISTERED_EMPLOYEE_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import {
  changeStatusColumn,
  formatDate
} from 'src/components/Tables/TableWithPagination/utils';
import { STATUS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import BreakdownSwitch from '../BreakdownSwitch';
import { createCounteragentFilter } from '../../const';

export const BREAKDOWNS_TABLE_NAME = 'BREAKDOWNS';
export const BREAKDOWNS_TABLE_PATH = 'asset.breakdowns.table';

export const TABLE_CONFIG_BASE = {
  reducerName: BREAKDOWNS_TABLE_NAME,
  reducerPath: BREAKDOWNS_TABLE_PATH,
  apiUrl: BREAKDOWNS
};

export const getColumns = (
  isAllowed: boolean,
  onChangeStatus: (row: Row) => Promise<void>
) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Дата несправності',
    value: 'registrationDate',
    sortable: true,
    width: '15%',
    formatValue: (row: Row) => formatDate(row.registrationDate)
  },
  {
    label: 'Підрозділ',
    value: 'counteragentFullName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Кількість обладнання',
    value: 'amountOfEquipment',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Хто зареєстрував',
    value: 'registeredByEmployeeName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус документа',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: changeStatusColumn,
    formatValue: (row: Row) =>
      row.status ? STATUS_CONFIG[row.status.toLowerCase()].label : ''
  },
  {
    label: '',
    value: '',
    sortable: false,
    changeValue: (row: Row) =>
      isAllowed ? (
        <BreakdownSwitch data={row} onChange={onChangeStatus} />
      ) : null,
    width: '5%'
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const REGISTERED_EMPLOYEE_NAME = 'REGISTERED_EMPLOYEE_NAME';
export const BREAKDOWNS_NOTES_AUTOCOMPLETE_NAME =
  'BREAKDOWNS_NOTES_AUTOCOMPLETE_NAME';

export const filters = [
  {
    type: FilterType.Date,
    name: 'registrationDate',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    },
    width: 220
  },
  createCounteragentFilter({
    width: 220
  }),
  {
    name: 'equipmentGroupId',
    label: 'Група обладнання',
    optionsURL: EQUIPMENT_GROUP_NAMES,
    width: 220
  },
  {
    name: 'registeredByEmployeeId',
    label: 'Хто зареєстрував',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: REGISTERED_EMPLOYEE_NAME,
      reducerPath: 'asset.breakdowns.registeredEmployeeAutocomplete',
      optionsApiUrl: REGISTERED_EMPLOYEE_AUTOCOMPLETE,
      value: 'id'
    },
    width: 220
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: 1
      },
      {
        label: 'Чернетка',
        value: 0
      }
    ],
    width: 220
  },
  {
    name: 'notes',
    label: 'Примітка',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: BREAKDOWNS_NOTES_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.breakdowns.notesAutocomplete',
      optionsApiUrl: BREAKDOWNS_NOTES_AUTOCOMPLETE
    },
    width: 220,
    value: 'name'
  }
];
