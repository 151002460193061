import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import { Row } from 'src/components/Tables/types';

export const normalizeEquipment = ({
  id,
  barcode,
  imei,
  equipment,
  spareParts
}: Row) => ({
  id,
  barcode,
  imei,
  equipmentId: equipment.id,
  spareParts
});

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectNormalizedList = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(normalizeEquipment)
  );

export const selectIsEquipmentListValid = (reducerPath: string) =>
  createSelector(
    selectListResult(reducerPath),
    list => !list.find(el => el.equipment.hasImei && !el.imei)
  );

export const selectEquipments = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(normalizeEquipment)
  );

export const selectEquipmentsImei = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.filter(el => el.imei).map(el => el.imei)
  );
