import { encode } from 'js-base64';
import { utcToLocal } from './date';
import { DATE_FORMAT_SLASH, DETAILED_DATE_TIME_FORMAT } from '../config';

export const generateJsonFile = (
  fields: (string | { label: string; fields: string[] })[],
  data: any
): string => {
  const file: { [key: string]: string | object } = {};
  fields.forEach(item => {
    if (typeof item === 'string') {
      file[item] = data[item] || '';
    } else {
      file[item.label] = JSON.parse(
        JSON.stringify(data[item.label], item.fields)
      );
    }

    if (
      typeof item === 'string' &&
      ['installationDate', 'uninstallationDate', 'registrationDate'].includes(
        item
      )
    ) {
      file[item] = utcToLocal(data[item], DATE_FORMAT_SLASH) || '';
    }

    if (typeof item === 'string' && ['modifiedAtUtc'].includes(item)) {
      file.modifiedAt = utcToLocal(data[item], DETAILED_DATE_TIME_FORMAT) || '';
      delete file.modifiedAtUtc;
    }
  });

  return JSON.stringify(file, null, 4);
};

export const getBase64Json = (json: string, fileName: string): string => {
  return `data:application/json;filename=${fileName}.json;base64,${encode(
    json
  )}`;
};
