import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { EQUIPMENT, UNINSTALLATIONS } from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import * as actions from './actions';
import { setLocalEquipmentsArray } from './utils';
import { selectListResult } from './selectors';

export function* getEquipments({
  payload
}: ReturnType<typeof actions.getEquipments.request>): SagaIterator {
  const {
    barcode,
    mobileTableConfig,
    stationaryTableConfig,
    inspectorTableConfig
  } = payload;
  const {
    data: { mobileEquipment, stationaryEquipment, inspectorEquipment }
  } = yield call(
    apiClient.get,
    `${EQUIPMENT}/barcode/${barcode}?installed=true`
  );

  const equipmentsLength =
    mobileEquipment.length +
    stationaryEquipment.length +
    inspectorEquipment.length;

  if (equipmentsLength > 1 || !equipmentsLength) {
    const message =
      equipmentsLength > 1
        ? `Знайдено кілька одиниць обладнання з однаковим штрихкодом (${barcode}). Перевірте обране обладнання.`
        : 'Такого штрихкоду не існує. Перевірте штрихкод.';

    yield put(
      enqueueSnackbar({
        key: 'uninstallation_equipments_snackbar',
        options: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          variant: 'warning'
        },
        message
      })
    );
  }

  const selectedMobileTableConfig: Row[] = yield select(
    selectListResult(mobileTableConfig.reducerPath)
  );
  const selectedStationaryTableConfig: Row[] = yield select(
    selectListResult(stationaryTableConfig.reducerPath)
  );
  if (
    mobileEquipment[0] &&
    selectedMobileTableConfig[0] &&
    (selectedMobileTableConfig[0].counteragent !==
      mobileEquipment[0].counteragent ||
      selectedMobileTableConfig[0].licensePlate !==
        mobileEquipment[0].licensePlate)
  ) {
    yield put(
      enqueueSnackbar({
        key: 'uninstallation_equipments_snackbar',
        options: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          variant: 'error'
        },
        message:
          'Обладнання, що демонтується, повинно знаходитись на одному підрозділі та транспортному засобі'
      })
    );
    yield put(actions.getEquipments.success());
    return;
  }

  if (
    stationaryEquipment[0] &&
    selectedStationaryTableConfig[0] &&
    (selectedStationaryTableConfig[0].counteragent !==
      stationaryEquipment[0].counteragent ||
      selectedStationaryTableConfig[0].responsibleEmployeeName !==
        stationaryEquipment[0].responsibleEmployeeName)
  ) {
    yield put(
      enqueueSnackbar({
        key: 'uninstallation_equipments_snackbar',
        options: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          variant: 'error'
        },
        message:
          'Обладнання, що демонтується, повинно знаходитись на одному підрозділі та у одної відповідальної особи'
      })
    );
    yield put(actions.getEquipments.success());
    return;
  }

  yield put(
    addRowsLocally(mobileTableConfig, {
      rows: setLocalEquipmentsArray(mobileEquipment)
    })
  );
  yield put(
    addRowsLocally(stationaryTableConfig, {
      rows: setLocalEquipmentsArray(stationaryEquipment)
    })
  );
  yield put(
    addRowsLocally(inspectorTableConfig, {
      rows: setLocalEquipmentsArray(inspectorEquipment)
    })
  );
  yield put(actions.getEquipments.success());
}

export function* approveUninstallation({
  payload: id
}: ReturnType<typeof actions.approveUninstallation.request>): SagaIterator {
  yield call(apiClient.put, `${UNINSTALLATIONS}/${id}/approve`);
  yield put(actions.approveUninstallation.success());
}

export default function* UninstallationEquipmentsStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEquipments.request),
      safe(getEquipments, actions.getEquipments.failure)
    ),
    takeEvery(
      getType(actions.approveUninstallation.request),
      safe(approveUninstallation, actions.approveUninstallation.failure)
    )
  ]);
}
