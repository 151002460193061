import { CARRIERS, ROUTE_PASSPORT } from 'src/config/avlUrls';
import { colors } from '@material-ui/core';
import { DeviationType } from './trafficStadium/types';

export const DISPATCHING_COUNTERAGENTS_NAME = 'DISPATCHING_COUNTERAGENTS';
export const DISPATCHING_COUNTERAGENTS_CONFIG = {
  prohibitDynamicLoad: true,
  optionsApiUrl: CARRIERS,
  reducerName: DISPATCHING_COUNTERAGENTS_NAME,
  reducerPath: 'avl.schedulesStatus.counteragents'
};

export const DISPATCHING_ROUTES_NAME = 'DISPATCHING_ROUTES';
export const DISPATCHING_ROUTES_PATH = 'avl.dispatching.routes';
export const getRoutesConfig = ({
  counteragentId,
  routeSchemaValidOn = ''
}: {
  counteragentId: number | string;
  routeSchemaValidOn?: string | null;
}) => ({
  prohibitDynamicLoad: true,
  optionsApiUrl: `${ROUTE_PASSPORT}?status=approved&tenderStatus=Completed&counteragentId=${counteragentId}&routeSchemaValidOn=${routeSchemaValidOn}`,
  reducerName: DISPATCHING_ROUTES_NAME,
  reducerPath: DISPATCHING_ROUTES_PATH
});

export const DISPATCHING_AUTOCOMPLETE_ROUTES_NAME =
  'DISPATCHING_AUTOCOMPLETE_ROUTES';
export const DISPATCHING_AUTOCOMPLETE_ROUTES_PATH =
  'avl.dispatching.routesAutocomplete';
export const getRoutesAutocopmleteConfig = ({
  counteragentId,
  routeSchemaValidOn = ''
}: {
  counteragentId: number | string;
  routeSchemaValidOn?: string | null;
}) => ({
  prohibitDynamicLoad: true,
  optionsApiUrl: `${ROUTE_PASSPORT}?status=approved&tenderStatus=Completed&counteragentId=${counteragentId}&routeSchemaValidOn=${routeSchemaValidOn}`,
  reducerName: DISPATCHING_AUTOCOMPLETE_ROUTES_NAME,
  reducerPath: DISPATCHING_AUTOCOMPLETE_ROUTES_PATH
});

export const colorDeviationTypeMap = {
  [DeviationType.NoDeviation]: colors.green[500],
  [DeviationType.TrivialDeviation]: colors.amber[400],
  [DeviationType.CriticalDeviation]: colors.red[500]
};
