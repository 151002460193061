import {
  Equipment,
  EquipmentResponse,
  Transfer,
  TransferForm,
  TransferPayload
} from '../../types';

export const normalizeFormResponse = (payload: Transfer): TransferForm => ({
  id: payload.id,
  documentNumber: payload.documentNumber,
  dateTransfer: payload.dateTransfer.split('T')[0],
  performingEmployee: payload.performingPersonId,
  performingEmployeeName: payload.performingPerson,
  acceptingEmployee: {
    id: payload.acceptingPersonId,
    name: payload.acceptingPersonFullName
  },
  counteragentHolder: {
    id: payload.counteragentHolderId,
    name: payload.counteragentHolderFullName
  },
  counteragentHolderAddress: payload.counteragentHolderAddress,
  counteragentTransfer: {
    id: payload.counteragentTransferId,
    name: payload.counteragentTransferFullName
  },
  counteragentTransferAddress: payload.counteragentTransferAddress,
  notes: payload.notes
});

export const normalizeFormRequest = (form: TransferForm): TransferPayload => {
  return {
    performingEmployeeId: form.performingEmployee,
    acceptingEmployeeId: form.acceptingEmployee?.id ?? 0,
    acceptingEployee: form.acceptingEmployee?.name ?? '',
    dateTransfer: form.dateTransfer,
    counteragentHolderId: form.counteragentHolder?.id || 0,
    counteragentTransferId: form.counteragentTransfer?.id || 0,
    notes: form.notes,
    equipment: []
  };
};

export const normalizeEquipmentRequest = (
  equipment: EquipmentResponse[]
): Partial<Equipment>[] => {
  return equipment.map(equipmentItem => ({
    id: equipmentItem.id,
    assetId: equipmentItem.assetId,
    barcode: equipmentItem.barcode,
    name: equipmentItem.name,
    productType: equipmentItem.model,
    manufacturer: equipmentItem.manufacturer,
    imei: equipmentItem.imei,
    counteragent: equipmentItem.counteragentHolderName,
    state: equipmentItem.state,
    equipmentStateId: equipmentItem.equipmentStateId,
    equipmentStateName: equipmentItem.equipmentStateName,
    spareParts: equipmentItem.spareParts
  }));
};
