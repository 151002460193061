import { combineReducers } from 'redux';
import { createNamedWrapperReducer } from 'src/utils/reducers';
import tableWithPaginationReducers from 'src/components/Tables/TableWithPagination/store/reducers';
import tableViewReducers from 'src/components/Tables/TableView/store/reducers';
import tableReducers from 'src/components/Tables/Table/store/reducers';
import autocompleteReducers from 'src/components/Form/FormAutocomplete/store/reducer';
import breakdownsTypesDetailsReducer from 'src/views/asset/breakdownsTypes/BreakdownsTypesDetailsView/store/reducers';
import equipmentEditReducers from 'src/views/asset/equipment/EquipmentEditView/store/reducers';
import { EQUIPMENT_TABLE_NAME } from 'src/views/asset/equipment/EquipmentListView/const';
import { BREAKDOWNS_TYPES_TABLE_NAME } from 'src/views/asset/breakdownsTypes/BreakdownsTypesListView/const';
import breakdownsTypesEditReducers from 'src/views/asset/breakdownsTypes/BreakdownsTypesEditView/store/reducer';
import {
  BREAKDOWNS_NOTES_AUTOCOMPLETE_NAME,
  BREAKDOWNS_TABLE_NAME,
  REGISTERED_EMPLOYEE_NAME
} from 'src/views/asset/breakdowns/BreakdownsListView/const';
import equipmentDetailsReducer from 'src/views/asset/equipment/EquipmentDetailsView/store/reducers';
import {
  CREATE_INSTALLATION_FORM_NAME,
  INSTALLING_EQUIPMENT_NAME
} from 'src/views/asset/installation/InstallationCreateView/const';

import {
  CREATE_INBOUND_FORM_NAME,
  INBOUNDING_EQUIPMENT_NAME
} from 'src/views/asset/inbound/InboundCreateView/const';

import {
  BREAKDOWNS_EQUIPMENT_TABLE_NAME,
  BREAKDOWNS_EQUIPMENT_NAME,
  CREATE_BREAKDOWNS_FORM_NAME
} from 'src/views/asset/breakdowns/BreakdownsCreateView/const';
import {
  BREAKDOWNS_BREAKDOWN_SELECT_NAME,
  BREAKDOWNS_EQUIPMENT_SELECT_NAME
} from 'src/views/asset/breakdowns/BreakdownsCreateView/EquipmentStepView/const';
import {
  EDIT_INSTALLATION_FORM_NAME,
  EDIT_INSTALLING_EQUIPMENT_NAME
} from 'src/views/asset/installation/InstallationEditView/const';
import {
  EDIT_INBOUND_FORM_NAME,
  EDIT_INBOUNDING_EQUIPMENT_NAME
} from 'src/views/asset/inbound/InboundEditView/const';
import {
  ACCEPTING_EMPLOYEES_NAME,
  COUNTERAGENTS_HOLDER_NAME,
  COUNTERAGENTS_NAME,
  EMPLOYEES_NAME,
  VEHICLE_NUMBERS_NAME
} from 'src/views/asset/installation/InstallationCreateView/InstallationStepView/const';
import { EQUIPMENT_NAME } from 'src/views/asset/installation/InstallationCreateView/EquipmentStepView/const';
import installationStepReducers from 'src/views/asset/installation/InstallationCreateView/InstallationStepView/store/reducer';

import {
  INBOUND_ACCEPTING_EMPLOYEES_NAME,
  INBOUND_COUNTERAGENT_TYPES_NAME,
  INBOUND_COUNTERAGENTS_NAME,
  INBOUND_EMPLOYEES_NAME,
  INBOUND_INBOUNDING_EMPLOYEES_NAME,
  INBOUND_VEHICLE_NUMBERS_NAME
} from 'src/views/asset/inbound/InboundCreateView/InboundStepView/const';
import { INBOUND_EQUIPMENT_NAME } from 'src/views/asset/inbound/InboundCreateView/EquipmentStepView/const';
import { INBOUND_EQUIPMENT_TABLE_NAME } from 'src/views/asset/inbound/InboundCreateView/ConfirmStepView/const';
import inboundStepReducers from 'src/views/asset/inbound/InboundCreateView/InboundStepView/store/reducer';

import uninstallationStepReducers from 'src/views/asset/uninstallation/UninstallationStep/store/reducer';
import {
  CREATE_UNINSTALLATION_FORM_NAME,
  UNINSTALLING_INSPECTOR_EQUIPMENT_NAME,
  UNINSTALLING_MOBILE_EQUIPMENT_NAME,
  UNINSTALLING_STATIONARY_EQUIPMENT_NAME
} from 'src/views/asset/uninstallation/UninstallationCreateView/const';
import {
  EDIT_UNINSTALLATION_FORM_NAME,
  EDIT_UNINSTALLING_INSPECTOR_EQUIPMENT_NAME,
  EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME,
  EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME
} from 'src/views/asset/uninstallation/UninstallationEditView/const';
import { UNINSTALLING_EMPLOYEES_NAME } from 'src/views/asset/uninstallation/UninstallationStep/const';
import uninstallationDetailsReducers from 'src/views/asset/uninstallation/UninstallationDetailsView/store/reducer';
import inspectionsFormReducer from 'src/views/asset/inspections/InspectionFormStep/store/reducer';
import {
  INSPECTION_COUNTERAGENTS_NAME,
  COUNTERAGENT_VEHICLES_NAME,
  PROFESSIONS_NAME,
  INSPECTOR_EMPLOYEES_NAME
} from 'src/views/asset/inspections/InspectionFormStep/const';
import { INSPECTIONS_EQUIPMENTS_SELECT_NAME } from 'src/views/asset/inspections/InspectionsEquipmentStep/const';
import {
  CREATE_INSPECTION_FORM_NAME,
  INSPECTIONS_EQUIPMENT_NAME
} from 'src/views/asset/inspections/InspectionsCreateView/const';
import {
  EDIT_INSPECTION_FORM_NAME,
  EDIT_INSPECTIONS_EQUIPMENT_NAME
} from 'src/views/asset/inspections/InspectionEditView/const';
import { EQUIPMENT_GROUP_SELECT_NAME } from 'src/views/asset/equipment/const';
import selectReducers from 'src/components/Select/store/reducers';
import {
  INSTALLATION_NOTES_AUTOCOMPLETE_NAME,
  INSTALLATION_TABLE_NAME,
  RESPONSIBLE_PERSON_AUTOCOMPLETE
} from 'src/views/asset/installation/InstallationListView/const';
import {
  INBOUND_TABLE_NAME,
  INBOUND_RESPONSIBLE_PERSON_AUTOCOMPLETE,
  INBOUND_NOTES_AUTOCOMPLETE_NAME
} from 'src/views/asset/inbound/InboundListView/const';
import {
  TRACKING_ADDRESS_AUTOCOMPLETE_NAME,
  TRACKING_BREAKDOWNS_AUTOCOMPLETE_NAME,
  TRACKING_IMEI_AUTOCOMPLETE_NAME,
  TRACKING_INSPECTOR_TABLE_NAME,
  TRACKING_MOBILE_TABLE_NAME,
  TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
  TRACKING_SPARE_PARTS_AUTOCOMPLETE_NAME,
  TRACKING_STATIONARY_TABLE_NAME,
  TRACKING_WAREHOUSE_TABLE_NAME
} from 'src/views/asset/tracking/TrackingListView/const';
import installationDetailsReducers from 'src/views/asset/installation/InstallationDetailsView/store/reducers';
import installationEmailDataReducers from 'src/views/asset/installation/InstallationListView/store/reducers';

import inboundDetailsReducers from 'src/views/asset/inbound/InboundDetailsView/store/reducers';
import inboundEmailDataReducers from 'src/views/asset/inbound/InboundListView/store/reducers';

import {
  RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
  UNINSTALLATION_NOTES_AUTOCOMPLETE_NAME,
  UNINSTALLATION_TABLE_NAME
} from 'src/views/asset/uninstallation/UninstallationListView/const';
import inspectionDetailsReducers from 'src/views/asset/inspections/InspectionDetailsView/store/reducer';
import {
  INSPECTION_NOTES_AUTOCOMPLETE_NAME,
  INSPECTIONS_TABLE_NAME
} from 'src/views/asset/inspections/InspectionsListView/const';
import {
  REPORT_COUNTERAGENT_AUTOCOMPLETE_NAME,
  REPORTS_RESPONSIBLE_PERSON_AUTOCOMPLETE_NAME,
  REPORT_ACTIONS_SELECT_NAME,
  REPORT_EQUIPMENTS_SELECT_NAME,
  REPORT_EQUIPMENT_GROUPS_SELECT_NAME,
  REPORT_VEHICLE_CATEGORIES_SELECT_NAME,
  REPORT_EMPLOYEES_AUTOCOMPLETE_NAME,
  REPORTS_TABLE_NAME,
  DOWNLOAD_REPORTS,
  BARCODE_AUTOCOMPLETE_NAME,
  IMEI_AUTOCOMPLETE_NAME,
  SPARE_PARTS_AUTOCOMPLETE_NAME,
  REPORT_EQUIPMENT_STATE_SELECT_NAME,
  BREAKDOWNS_AUTOCOMPLETE_NAME,
  DOCUMENT_NUMBER_AUTOCOMPLETE_NAME
} from 'src/views/asset/reports/const';
import breakdownStepReducer from 'src/views/asset/breakdowns/BreakdownsCreateView/BreakdownsStepView/store/reducers';
import breakdownDetailsReducers from 'src/views/asset/breakdowns/BreakdownDetailsView/store/reducers';
import {
  EDIT_BREAKDOWNS_EQUIPMENT_NAME,
  EDIT_BREAKDOWN_FORM_NAME
} from 'src/views/asset/breakdowns/BreakdownsEditView/const';
import inspectionEmailDataReducers from 'src/views/asset/inspections/InspectionsListView/store/reducer';
import breakdownListViewReducers from 'src/views/asset/breakdowns/BreakdownsListView/store/reducers';
import downloadReportsReducer from '../../views/asset/reports/store/reducers';
import { STATES_TABLE_NAME } from '../../views/asset/states/StatesListView/const';
import stateDetailsReducer from '../../views/asset/states/StatesDetailsView/store/reducers';
import statesEditReducer from '../../views/asset/states/StatesEditView/store/reducer';
import transferStepReducer from '../../views/asset/transfer/TransferStep/store/reducer';
import transferDetailsReducers from '../../views/asset/transfer/TransferDetailsView/store/reducer';
import {
  CREATE_TRANSFER_FORM_NAME,
  TRANSFERING_EQUIPMENT_NAME
} from '../../views/asset/transfer/TransferCreateView/const';
import {
  EDIT_TRANSFER_FORM_NAME,
  EDIT_TRANSFERING_EQUIPMENT_NAME
} from '../../views/asset/transfer/TransferEditView/const';
import {
  TRANSFER_NOTES_AUTOCOMPLETE_NAME,
  TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
  TRANSFER_TABLE_NAME
} from '../../views/asset/transfer/TransferListView/const';
import {
  TRANSFERING_COUNTERAGENTS_HOLDER_NAME,
  TRANSFERING_COUNTERAGENTS_TRANSFERING_NAME
} from '../../views/asset/transfer/TransferStep/const';
import stateChangeStepReducer from '../../views/asset/stateChange/StateChangeStep/store/reducer';
import {
  CREATE_STATE_CHANGE_FORM_NAME,
  STATE_CHANGING_EQUIPMENT_NAME
} from '../../views/asset/stateChange/StateChangeCreateView/const';
import {
  EDIT_STATE_CHANGE_FORM_NAME,
  EDIT_STATE_CHANGING_EQUIPMENT_NAME
} from '../../views/asset/stateChange/StateChangeEditView/const';
import {
  STATE_CHANGES_NOTES_AUTOCOMPLETE_NAME,
  STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
  STATE_CHANGE_TABLE_NAME
} from '../../views/asset/stateChange/StateChangeListView/const';
import { STATE_CHANGING_COUNTERAGENTS_NAME } from '../../views/asset/stateChange/StateChangeStep/const';
import stateChangeDetailsReducers from '../../views/asset/stateChange/StateChangeDetailsView/store/reducer';
import { STATECHANGE_STATE_SELECT_NAME } from '../../views/asset/stateChange/StateChangeEquipmentsStep/const';
import {
  CREATE_REPLACEMENT_FORM_NAME,
  REPLACING_INSTALLING_EQUIPMENT_NAME,
  REPLACING_UNINSTALLING_EQUIPMENT_NAME
} from '../../views/asset/replacement/const';
import { COUNTERAGENTS_TRANSFER_NAME } from '../../views/asset/replacement/ReplacementCreateView/ReplacementStepView/const';
import { COUNTERAGENTS_FILTER_NAME } from '../../views/asset/const';

const assetReducers = combineReducers({
  counteragents: createNamedWrapperReducer(
    autocompleteReducers,
    COUNTERAGENTS_FILTER_NAME
  ),
  replacement: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        installationStepReducers,
        CREATE_REPLACEMENT_FORM_NAME
      ),
      replacementInstallingTable: createNamedWrapperReducer(
        tableViewReducers,
        REPLACING_INSTALLING_EQUIPMENT_NAME
      ),
      replacementUninstallingTable: createNamedWrapperReducer(
        tableViewReducers,
        REPLACING_UNINSTALLING_EQUIPMENT_NAME
      )
    })
  }),
  installation: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        installationStepReducers,
        CREATE_INSTALLATION_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        INSTALLING_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        installationStepReducers,
        EDIT_INSTALLATION_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_INSTALLING_EQUIPMENT_NAME
      )
    }),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      INSTALLATION_TABLE_NAME
    ),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ACCEPTING_EMPLOYEES_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_NAME
    ),
    counteragentsHolderAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_HOLDER_NAME
    ),
    employeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      EMPLOYEES_NAME
    ),
    vehicleNumbersAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      VEHICLE_NUMBERS_NAME
    ),
    equipmentAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      EQUIPMENT_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      RESPONSIBLE_PERSON_AUTOCOMPLETE
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INSTALLATION_NOTES_AUTOCOMPLETE_NAME
    ),
    details: installationDetailsReducers,
    emailData: installationEmailDataReducers
  }),
  inbound: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        inboundStepReducers,
        CREATE_INBOUND_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        INBOUNDING_EQUIPMENT_NAME
      ),
      equipment: createNamedWrapperReducer(
        tableReducers,
        INBOUND_EQUIPMENT_TABLE_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        inboundStepReducers,
        EDIT_INBOUND_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_INBOUNDING_EQUIPMENT_NAME
      )
    }),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      INBOUND_TABLE_NAME
    ),
    inboundingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_INBOUNDING_EMPLOYEES_NAME
    ),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_ACCEPTING_EMPLOYEES_NAME
    ),
    counteragentTypesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_COUNTERAGENT_TYPES_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_COUNTERAGENTS_NAME
    ),
    employeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_EMPLOYEES_NAME
    ),
    vehicleNumbersAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_VEHICLE_NUMBERS_NAME
    ),
    equipmentAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_EQUIPMENT_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_RESPONSIBLE_PERSON_AUTOCOMPLETE
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INBOUND_NOTES_AUTOCOMPLETE_NAME
    ),
    details: inboundDetailsReducers,
    emailData: inboundEmailDataReducers
  }),
  breakdowns: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        breakdownStepReducer,
        CREATE_BREAKDOWNS_FORM_NAME
      ),
      breakdownsTable: createNamedWrapperReducer(
        tableViewReducers,
        BREAKDOWNS_EQUIPMENT_NAME
      ),
      equipment: createNamedWrapperReducer(
        tableReducers,
        BREAKDOWNS_EQUIPMENT_TABLE_NAME
      )
    }),
    breakdownSelect: createNamedWrapperReducer(
      selectReducers,
      BREAKDOWNS_BREAKDOWN_SELECT_NAME
    ),
    equipmentSelect: createNamedWrapperReducer(
      selectReducers,
      BREAKDOWNS_EQUIPMENT_SELECT_NAME
    ),
    vehicleNumbersAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      VEHICLE_NUMBERS_NAME
    ),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ACCEPTING_EMPLOYEES_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_NAME
    ),
    registeredEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      REGISTERED_EMPLOYEE_NAME
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      BREAKDOWNS_NOTES_AUTOCOMPLETE_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      BREAKDOWNS_TABLE_NAME
    ),
    details: breakdownDetailsReducers,
    edit: combineReducers({
      data: createNamedWrapperReducer(
        breakdownStepReducer,
        EDIT_BREAKDOWN_FORM_NAME
      ),
      breakdownsTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_BREAKDOWNS_EQUIPMENT_NAME
      ),
      equipment: createNamedWrapperReducer(
        tableReducers,
        BREAKDOWNS_EQUIPMENT_TABLE_NAME
      )
    }),
    emailData: breakdownListViewReducers
  }),
  uninstallation: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        uninstallationStepReducers,
        CREATE_UNINSTALLATION_FORM_NAME
      ),
      mobileTable: createNamedWrapperReducer(
        tableViewReducers,
        UNINSTALLING_MOBILE_EQUIPMENT_NAME
      ),
      stationaryTable: createNamedWrapperReducer(
        tableViewReducers,
        UNINSTALLING_STATIONARY_EQUIPMENT_NAME
      ),
      inspectorTable: createNamedWrapperReducer(
        tableViewReducers,
        UNINSTALLING_INSPECTOR_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        uninstallationStepReducers,
        EDIT_UNINSTALLATION_FORM_NAME
      ),
      mobileTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME
      ),
      stationaryTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME
      ),
      inspectorTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_UNINSTALLING_INSPECTOR_EQUIPMENT_NAME
      )
    }),
    uninstallingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      UNINSTALLING_EMPLOYEES_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME
    ),
    counteragentsTransferAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_TRANSFER_NAME
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      UNINSTALLATION_NOTES_AUTOCOMPLETE_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      UNINSTALLATION_TABLE_NAME
    ),
    details: uninstallationDetailsReducers
  }),

  transfer: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        transferStepReducer,
        CREATE_TRANSFER_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        TRANSFERING_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        transferStepReducer,
        EDIT_TRANSFER_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_TRANSFERING_EQUIPMENT_NAME
      )
    }),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ACCEPTING_EMPLOYEES_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRANSFER_TABLE_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME
    ),
    counteragentsHolderAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRANSFERING_COUNTERAGENTS_HOLDER_NAME
    ),
    counteragentsTransferAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRANSFERING_COUNTERAGENTS_TRANSFERING_NAME
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRANSFER_NOTES_AUTOCOMPLETE_NAME
    ),
    details: transferDetailsReducers,
    emailData: transferDetailsReducers
  }),
  stateChange: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        stateChangeStepReducer,
        CREATE_STATE_CHANGE_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        STATE_CHANGING_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        stateChangeStepReducer,
        EDIT_STATE_CHANGE_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_STATE_CHANGING_EQUIPMENT_NAME
      )
    }),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ACCEPTING_EMPLOYEES_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      STATE_CHANGE_TABLE_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      STATE_CHANGING_COUNTERAGENTS_NAME
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      STATE_CHANGES_NOTES_AUTOCOMPLETE_NAME
    ),
    statesSelect: createNamedWrapperReducer(
      selectReducers,
      STATECHANGE_STATE_SELECT_NAME
    ),
    details: stateChangeDetailsReducers
  }),
  inspections: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        inspectionsFormReducer,
        CREATE_INSPECTION_FORM_NAME
      ),
      equipmentTable: createNamedWrapperReducer(
        tableViewReducers,
        INSPECTIONS_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        inspectionsFormReducer,
        EDIT_INSPECTION_FORM_NAME
      ),
      equipmentTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_INSPECTIONS_EQUIPMENT_NAME
      )
    }),
    details: inspectionDetailsReducers,
    inspectorEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INSPECTOR_EMPLOYEES_NAME
    ),
    notesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INSPECTION_NOTES_AUTOCOMPLETE_NAME
    ),
    professions: createNamedWrapperReducer(selectReducers, PROFESSIONS_NAME),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INSPECTION_COUNTERAGENTS_NAME
    ),
    counteragentVehiclesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENT_VEHICLES_NAME
    ),
    equipmentsSelect: createNamedWrapperReducer(
      selectReducers,
      INSPECTIONS_EQUIPMENTS_SELECT_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      INSPECTIONS_TABLE_NAME
    ),
    emailData: inspectionEmailDataReducers
  }),
  equipment: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      EQUIPMENT_TABLE_NAME
    ),
    edit: equipmentEditReducers,
    create: combineReducers({
      equipmentGroupSelect: createNamedWrapperReducer(
        selectReducers,
        EQUIPMENT_GROUP_SELECT_NAME
      )
    }),
    details: equipmentDetailsReducer
  }),
  breakdownsTypes: combineReducers({
    details: breakdownsTypesDetailsReducer,
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      BREAKDOWNS_TYPES_TABLE_NAME
    ),
    edit: breakdownsTypesEditReducers
  }),
  tracking: combineReducers({
    mobileTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRACKING_MOBILE_TABLE_NAME
    ),
    stationaryTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRACKING_STATIONARY_TABLE_NAME
    ),
    inspectorTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRACKING_INSPECTOR_TABLE_NAME
    ),
    warehouseTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRACKING_WAREHOUSE_TABLE_NAME
    ),
    imeiAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRACKING_IMEI_AUTOCOMPLETE_NAME
    ),
    sparePartsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRACKING_SPARE_PARTS_AUTOCOMPLETE_NAME
    ),
    addressAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRACKING_ADDRESS_AUTOCOMPLETE_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME
    ),
    breakdownsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      TRACKING_BREAKDOWNS_AUTOCOMPLETE_NAME
    )
  }),
  reports: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      REPORTS_TABLE_NAME
    ),
    reportCounteragentAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      REPORT_COUNTERAGENT_AUTOCOMPLETE_NAME
    ),
    reportResponsiblePersonAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      REPORTS_RESPONSIBLE_PERSON_AUTOCOMPLETE_NAME
    ),
    reportActionSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_ACTIONS_SELECT_NAME
    ),
    reportEquipmentSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_EQUIPMENTS_SELECT_NAME
    ),
    reportEquipmentGroupSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_EQUIPMENT_GROUPS_SELECT_NAME
    ),
    reportVehicleCategoriesSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_VEHICLE_CATEGORIES_SELECT_NAME
    ),
    reportEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      REPORT_EMPLOYEES_AUTOCOMPLETE_NAME
    ),
    reportsDownload: createNamedWrapperReducer(
      downloadReportsReducer,
      DOWNLOAD_REPORTS
    ),
    barcodeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      BARCODE_AUTOCOMPLETE_NAME
    ),
    documentNumberAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      DOCUMENT_NUMBER_AUTOCOMPLETE_NAME
    ),
    imeiAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      IMEI_AUTOCOMPLETE_NAME
    ),
    sparePartsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      SPARE_PARTS_AUTOCOMPLETE_NAME
    ),
    reportEquipmentStateSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_EQUIPMENT_STATE_SELECT_NAME
    ),
    breakdownsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      BREAKDOWNS_AUTOCOMPLETE_NAME
    )
  }),
  states: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      STATES_TABLE_NAME
    ),
    details: stateDetailsReducer,
    edit: statesEditReducer
  })
});

export default assetReducers;
