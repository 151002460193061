import { PdfGenerator, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DETAILED_DATE_TIME_FORMAT } from 'src/config';
import { stateChangeDocumentPdf, stateChangeColumnsPdf } from '../const';
import { StateChangeDetailsPayload } from '../types';

export function generatePdfOfStateChange(
  data: StateChangeDetailsPayload
): PdfGenerator {
  const stateChangeData = {
    ...data,
    modifiedAtUtc: utcToLocal(data.modifiedAtUtc, DETAILED_DATE_TIME_FORMAT)
  };

  const {
    documentNumber,
    equipment,
    stateChangeDate,
    employeeName
  } = stateChangeData;

  const dateTitle = utcToLocal(stateChangeDate, DATE_FORMAT_FULL);
  const dateSubject = utcToLocal(stateChangeDate, DATE_FORMAT_FULL);

  const title = `Зміна стану №${documentNumber} ${employeeName} ${dateTitle}`;
  const subject = `Акт зміни стану № ${documentNumber} від ${dateSubject}`;

  return new PdfGenerator(title, 13, { orientation: 'landscape' })
    .setHeader(subject, true)
    .setTable(stateChangeDocumentPdf, stateChangeData, TypeTable.InfoTable)
    .setHeader('Обладнання зі зміненим станом', false)
    .setTable(stateChangeColumnsPdf, equipment, TypeTable.ListTable);
}
