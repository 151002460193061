import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INBOUNDS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectEquipments } from '../../InboundCreateView/EquipmentStepView/store/selectors';
import {
  selectNormalizeData,
  selectInboundId
} from '../../InboundCreateView/InboundStepView/store/selectors';

export function* updateInbound({
  config,
  resolve
}: ReturnType<typeof actions.updateInboundRequest>): SagaIterator {
  const id = yield select(selectInboundId);
  const { dateInbound } = yield select(
    selectNormalizeData(config.dataReducerPath)
  );
  const equipment = yield select(selectEquipments(config.tableReducerPath));

  yield call(
    apiClient.put,
    `${INBOUNDS}/${id}`,
    { dateInbound, equipment },
    { retryAction: actions.updateInboundRequest(config, resolve) }
  );

  yield call(resolve, id);
  yield put(actions.updateInboundSuccess(config));
}

export default function* InboundEditSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.updateInboundRequest),
    safe(updateInbound, actions.updateInboundFailure)
  );
}
