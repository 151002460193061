import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { StateChangeDetailsPayload } from '../../types';
import { StateChangeStatusEnum } from '../../const';

const initialState: StateChangeDetailsPayload = {
  id: 0,
  documentNumber: '',
  stateChangeDate: '',
  name: '',
  counteragentAddress: '',
  counteragentName: '',
  counteragentFullName: '',
  status: StatusEnum.Fixed,
  statusId: StateChangeStatusEnum.Fixed,
  employeeName: '',
  notes: '',
  equipment: [],
  employeeFullName: '',
  createdAtUtc: null,
  modifiedAtUtc: null
};

const stateChangeDetailsReducers = createReducer<
  StateChangeDetailsPayload,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getStateChange.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default stateChangeDetailsReducers;
