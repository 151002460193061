import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import {
  EQUIPMENT,
  INSTALLATIONS_EQUIPMENT_VALIDATION
} from 'src/config/assetUrls';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';

export function* getEquipments({
  payload
}: ReturnType<typeof actions.getEquipments.request>): SagaIterator {
  const { barcode, counteragentName } = payload;

  const {
    data: { mobileEquipment, stationaryEquipment, inspectorEquipment }
  } = yield call(apiClient.get, `${EQUIPMENT}/barcode/${barcode}`);

  const equipmentsLength =
    mobileEquipment.length +
    stationaryEquipment.length +
    inspectorEquipment.length;

  if (!equipmentsLength) {
    throw new Error(
      `Даного обладнання не знайдено на підрозділі ${counteragentName}.`
    );
  } else {
    yield put(
      actions.getEquipments.success(
        mobileEquipment[0] || stationaryEquipment[0] || inspectorEquipment[0]
      )
    );
  }
}

export function* validateEquipment({
  payload
}: ReturnType<typeof actions.validateEquipment.request>): SagaIterator {
  yield call(apiClient.post, INSTALLATIONS_EQUIPMENT_VALIDATION, payload, {
    retryAction: actions.validateEquipment.request(payload)
  });
  yield put(actions.validateEquipment.success());
}

export default function* ReplacementEquipmentStepSaga(): SagaIterator {
  yield all([
    yield takeEvery(
      getType(actions.validateEquipment.request),
      safe(validateEquipment, actions.validateEquipment.failure)
    ),
    takeEvery(
      getType(actions.getEquipments.request),
      safe(getEquipments, actions.getEquipments.failure)
    )
  ]);
}
