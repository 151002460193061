import { STATE_CHANGES } from 'src/config/assetUrls';

export const EDIT_STATE_CHANGE_FORM_NAME = 'EDIT_STATE_CHANGE_FORM';
export const EDIT_STATE_CHANGE_FORM_PATH = 'asset.stateChange.edit.data';
export const EDIT_STATE_CHANGE_FORM_CONFIG = {
  reducerName: EDIT_STATE_CHANGE_FORM_NAME,
  reducerPath: EDIT_STATE_CHANGE_FORM_PATH
};

export const EDIT_STATE_CHANGING_EQUIPMENT_NAME =
  'EDIT_STATE_CHANGING_EQUIPMENT_NAME';
export const EDIT_STATE_CHANGING_EQUIPMENT_PATH =
  'asset.stateChange.edit.table';

export const EDIT_STATE_CHANGING_TABLE_CONFIG = (id: number) => ({
  reducerName: EDIT_STATE_CHANGING_EQUIPMENT_NAME,
  reducerPath: EDIT_STATE_CHANGING_EQUIPMENT_PATH,
  apiUrl: `${STATE_CHANGES}/${id}/equipment/stationary`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено зміну стану'
  }
});

export const content = 'Ви впевнені, що хочете завершити зміну стану?';

export const STATE_CHANGE_ERROR_MESSAGES: Record<string, string> = {
  stateNotChanged: 'Оберіть стан обладнання який відрізняється від поточного',
  forbiddenDecommissioningOfInstalledAsset:
    'Стан Виведено не використовується для встановленого обладнання. Даний стан дозволяється тільки для невстановленого обладнання, що знаходиться на складах'
};
