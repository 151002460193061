import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import { STATES } from 'src/config/assetUrls';
import { Sorter } from 'src/components/Tables/TableWithPagination/types';
import { crudActions } from '../const';
import TextWithEllipsis from '../../../../components/TextWithEllipsis';
import StateTypeColumn from './StateTypeColumn';

export const columns = [
  {
    label: 'Стан',
    value: 'name',
    width: '20%',
    changeValue: (row: Row) => <StateTypeColumn id={row.id} name={row.name} />
  },
  {
    label: 'Опис стану',
    value: 'description',
    sortable: true,
    width: '30%'
  },
  {
    label: 'Дата',
    value: 'date',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Працівник',
    value: 'employeeName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '20%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const STATES_TABLE_PATH = 'asset.states.table';
export const STATES_TABLE_NAME = 'STATES';

export const tableConfig = {
  action: crudActions.create,
  reducerName: STATES_TABLE_NAME,
  reducerPath: STATES_TABLE_PATH,
  apiUrl: STATES,
  routeUrl: '/asset/dictionaries/states/',
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: (row: Row) => row.canModify,
      action: crudActions.update,
      disabledTooltip: 'Неможливо редагувати. Стан використовується'
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) => row.canModify,
      action: crudActions.delete,
      disabledTooltip: 'Неможливо видалити. Стан використовується'
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete,
      disabled: (selectedRows: Row[]) =>
        selectedRows.every(row => row.canModify === false)
    }
  },
  isRowClickEnabled: true
};

export const defaultSorters: Sorter[] = [{ propertyName: 'id', order: 'ASC' }];
