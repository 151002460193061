import { object, string, number } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { EQUIPMENT_AUTOCOMPLETE } from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';
import Label from 'src/components/Labels/Label';
import TextWithEllipsis from 'src/components/TextWithEllipsis';

export const INBOUND_EQUIPMENT_NAME = 'EQUIPMENT_AUTOCOMPLETE';
export const INBOUND_EQUIPMENT_PATH = 'asset.inbound.equipmentAutocomplete';

export const EQUIPMENT_CONFIG = (equipmentId: number) => ({
  optionsApiUrl: `${EQUIPMENT_AUTOCOMPLETE}`,
  reducerName: INBOUND_EQUIPMENT_NAME,
  reducerPath: INBOUND_EQUIPMENT_PATH
});

export const initialFormState = {
  isOpen: false,
  isEditMode: false,
  values: {}
};

export const columns = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '25%'
  },
  {
    label: 'Назва',
    value: 'equipment.name',
    sortable: true,
    width: '25%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '25%',
    changeValue: (row: Row) =>
      row.equipment.hasImei && !row.imei ? (
        <Label color="warning">Потрібно просканувати</Label>
      ) : (
        row.imei || null
      )
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    width: '25%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.spareParts} />
  }
];

export const equipmentFormValidationScheme = object().shape({
  barcode: string()
    .matches(/^\d+$/, ERROR_MESSAGES.TYPE_ERROR)
    .max(13, 'Значення повинно містити не більше 13 цифр')
    .required(ERROR_MESSAGES.REQUIRED),
  equipment: object()
    .nullable(false)
    .shape({
      id: number()
    })
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  imei: string()
    .when(['equipment.hasImei'], {
      is: (hasImei: boolean) => hasImei,
      then: string()
        .matches(/^\d{15}$/, 'Значення повинно містити 15 цифр')
        .required(ERROR_MESSAGES.REQUIRED)
    })
    .when(['$imei', '$equipmentsImei', '$isSame'], {
      is: (imei: string, equipmentsImei: string[], isSame: boolean) =>
        !isSame && equipmentsImei.includes(imei),
      then: string().test({
        test() {
          throw this.createError({
            message: ERROR_MESSAGES.UNIQUE,
            path: 'imei'
          });
        }
      })
    }),
  spareParts: string().max(350, 'Введіть значення не більше 350 символів')
});
