import { Schema, date, object, number, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import {
  EMPLOYEES_AUTOCOMPLETE,
  COUNTERAGENTS_AUTOCOMPLETE,
  COUNTERAGENTS
} from 'src/config/avlUrls';
import { isToday } from 'date-fns';
import { EquipmentTypeEnum } from 'src/constants';

const employeesValuesValidation = (
  accepting: AutocompleteOption | null,
  installingId: number,
  schema: Schema<{}>
) =>
  schema.test({
    test: () => !!(accepting && accepting.id !== installingId),
    message: accepting && installingId ? ERROR_MESSAGES.UNIQUE : ''
  });

export const installationValidationSchema = object().shape({
  replacementDate: date()
    .required(ERROR_MESSAGES.REQUIRED)
    .test({ message: ERROR_MESSAGES.TYPE_ERROR, test: isToday })
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .nullable(),
  replacingEmployee: number(),
  acceptingEmployee: object()
    .shape({
      id: number()
    })
    .typeError(ERROR_MESSAGES.REQUIRED)
    .when(
      ['$acceptingEmployee', '$installingEmployee'],
      employeesValuesValidation
    ),
  equipmentType: number().required(ERROR_MESSAGES.REQUIRED),
  counteragentHolder: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  counteragentTransfer: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  counteragent: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  vehicleNumbers: object()
    .shape({ id: number() })
    .when('$equipmentType', {
      is: equipmentType => equipmentType === EquipmentTypeEnum.Mobile,
      then: object()
        .required(ERROR_MESSAGES.REQUIRED)
        .typeError(ERROR_MESSAGES.REQUIRED),
      otherwise: object().nullable()
    })
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes: string().max(255, 'Введіть значення не більше 255 символів')
});

export const ACCEPTING_EMPLOYEES_NAME = 'ACCEPTING_EMPLOYEES_AUTOCOMPLETE';
export const ACCEPTING_EMPLOYEES_PATH =
  'asset.installation.acceptingEmployeesAutocomplete';

export const ACCEPTING_EMPLOYEE_CONFIG = {
  optionsApiUrl: `${EMPLOYEES_AUTOCOMPLETE}?status=Enabled`,
  reducerName: ACCEPTING_EMPLOYEES_NAME,
  reducerPath: ACCEPTING_EMPLOYEES_PATH
};

export const COUNTERAGENTS_NAME = 'COUNTERAGENTS_AUTOCOMPLETE';
export const COUNTERAGENTS_PATH =
  'asset.installation.counteragentsAutocomplete';

export const COUNTERAGENT_CONFIG = {
  optionsApiUrl: COUNTERAGENTS_AUTOCOMPLETE,
  reducerName: COUNTERAGENTS_NAME,
  reducerPath: COUNTERAGENTS_PATH
};

export const COUNTERAGENTS_HOLDER_NAME = 'COUNTERAGENTS_HOLDER_AUTOCOMPLETE';
export const COUNTERAGENTS_HOLDER_PATH =
  'asset.installation.counteragentsHolderAutocomplete';

export const getCounteragentHolderConfig = (counteragentTypeId?: number) => ({
  optionsApiUrl: counteragentTypeId
    ? `${COUNTERAGENTS_AUTOCOMPLETE}?counteragentTypeId=${counteragentTypeId}`
    : '',
  reducerName: COUNTERAGENTS_HOLDER_NAME,
  reducerPath: COUNTERAGENTS_HOLDER_PATH
});

export const COUNTERAGENT_HOLDER_CONFIG = getCounteragentHolderConfig();

export const COUNTERAGENTS_TRANSFER_NAME =
  'COUNTERAGENTS_TRANSFER_AUTOCOMPLETE';
export const COUNTERAGENTS_TRANSFER_PATH =
  'asset.uninstallation.counteragentsTransferAutocomplete';

export const getCounteragentTransferConfig = (counteragentTypeId?: number) => ({
  optionsApiUrl: counteragentTypeId
    ? `${COUNTERAGENTS_AUTOCOMPLETE}?counteragentTypeId=${counteragentTypeId}`
    : '',
  reducerName: COUNTERAGENTS_TRANSFER_NAME,
  reducerPath: COUNTERAGENTS_TRANSFER_PATH
});

export const COUNTERAGENT_TRANSFER_CONFIG = getCounteragentTransferConfig();

export const EMPLOYEES_NAME = 'EMPLOYEES_AUTOCOMPLETE';
export const EMPLOYEES_PATH = 'asset.installation.employeesAutocomplete';

export const getEmployeesConfig = (id: number | string) => ({
  optionsApiUrl: id ? `${COUNTERAGENTS}/${id}/employees-autocomplete` : '',
  reducerName: EMPLOYEES_NAME,
  reducerPath: EMPLOYEES_PATH
});

export const VEHICLE_NUMBERS_NAME = 'VEHICLE_NUMBERS_AUTOCOMPLETE';
export const VEHICLE_NUMBERS_PATH =
  'asset.installation.vehicleNumbersAutocomplete';

export const getVehicleNumbersConfig = (id: number | string) => ({
  optionsApiUrl: id
    ? `${COUNTERAGENTS}/${id}/vehicles-autocomplete?status=Enabled`
    : '',
  reducerName: VEHICLE_NUMBERS_NAME,
  reducerPath: VEHICLE_NUMBERS_PATH
});
