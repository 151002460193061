import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectNormalizedList } from '../EquipmentStepView/store/selectors';
import { selectNormalizeData } from '../InstallationStepView/store/selectors';
import { getInstallationRowData } from '../../InstallationListView/store/actions';
import {
  clearTableState,
  getDataRequest
} from '../../../../../components/Tables/TableView/store/actions';
import { getTableViewConfig } from '../const';

export function* registerInstallation({
  payload
}: ReturnType<typeof actions.registerInstallation.request>): SagaIterator {
  const { config } = payload;

  const installationData = yield select(
    selectNormalizeData(config.dataReducerPath)
  );
  const equipment = yield select(selectNormalizedList(config.tableReducerPath));

  const { data: installationId } = yield call(
    apiClient.post,
    INSTALLATIONS,
    { ...installationData, equipment },
    { retryAction: actions.registerInstallation.request(payload) }
  );

  // clear data and get updated one
  yield put(clearTableState(getTableViewConfig(installationId)));
  yield put(getDataRequest(getTableViewConfig(installationId)));

  yield put(getInstallationRowData.request({ id: installationId }));

  yield put(actions.registerInstallation.success(installationId));
}

export default function* InstallationCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.registerInstallation.request),
    safe(registerInstallation, actions.registerInstallation.failure)
  );
}
