import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ReportsData } from './types';

type Props = {
  report: ReportsData;
};

const ACTION_TYPE_MAP: Record<string, string> = {
  Несправність: 'breakdowns',
  Монтаж: 'installation',
  Демонтаж: 'uninstallation',
  Інспекція: 'inspections',
  Надходження: 'inbound',
  'Зміна стану': 'state-changes',
  Переміщення: 'transfer'
};

const useStyles = makeStyles(({ palette }) => ({
  link: {
    textDecoration: 'none',
    borderBottom: `2px dashed ${palette.primary.light}`,
    color: palette.primary.light
  }
}));

export const DocumentLink = ({
  report: { actionType, documentId, documentNumber }
}: Props) => {
  const classes = useStyles();
  const link = `/asset/${ACTION_TYPE_MAP[actionType]}/${documentId}/details`;

  return (
    <Link target="_blank" to={link} className={classes.link}>
      {documentNumber}
    </Link>
  );
};
