import { Row } from 'src/components/Tables/types';
import {
  changeStatusColumn,
  formatDate
} from 'src/components/Tables/TableWithPagination/utils';
import {
  UNINSTALLATION_NOTES_AUTOCOMPLETE,
  UNINSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
  UNINSTALLATIONS
} from 'src/config/assetUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import { STATUS_CONFIG } from 'src/constants';
import {
  UninstallationStatusEnum,
  uninstallationStatusEnumStatusMap
} from '../const';
import UninstallationSwitchColumn from './UninstallationSwitchColumn';
import { createCounteragentHolderFilter } from '../../const';

export const UNINSTALLATION_TABLE_PATH = 'asset.uninstallation.table';
export const UNINSTALLATION_TABLE_NAME = 'UNINSTALLATION';

export const TABLE_CONFIG_BASE = {
  reducerName: UNINSTALLATION_TABLE_NAME,
  reducerPath: UNINSTALLATION_TABLE_PATH,
  apiUrl: UNINSTALLATIONS
};

export const getColumns = (
  isShowEditAllowed: boolean,
  onChangeStatus: (row: Row) => Promise<void>
) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата демонтажу',
    value: 'uninstallationDate',
    sortable: true,
    width: '15%',
    formatValue: (row: Row) => formatDate(row.uninstallationDate)
  },
  {
    label: 'Підрозділ до',
    value: 'counteragentHolderFullName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Кількість обладнання',
    value: 'uninstalledEquipmentCount',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Хто демонтував',
    value: 'uninstallingEmployeeName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус документа',
    value: 'statusId',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) =>
      changeStatusColumn({
        ...row,
        status:
          uninstallationStatusEnumStatusMap[
            row.statusId as UninstallationStatusEnum
          ]
      }),
    formatValue: (row: Row) =>
      STATUS_CONFIG[
        uninstallationStatusEnumStatusMap[
          row.statusId as UninstallationStatusEnum
        ].toLowerCase()
      ].label
  },
  {
    label: '',
    value: '',
    sortable: false,
    width: '5%',
    changeValue: (row: Row) =>
      isShowEditAllowed ? (
        <UninstallationSwitchColumn data={row} onChange={onChangeStatus} />
      ) : null
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME =
  'INSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE';

export const UNINSTALLATION_NOTES_AUTOCOMPLETE_NAME =
  'UNINSTALLATION_NOTES_AUTOCOMPLETE';

export const getFilters = () => [
  {
    type: FilterType.Date,
    name: 'uninstallationDate',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    }
  },
  createCounteragentHolderFilter({
    label: 'Підрозділ до'
  }),
  {
    name: 'uninstallingEmployeeId',
    label: 'Хто демонтував',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.uninstallation.responsibleEmployeeAutocomplete',
      optionsApiUrl: UNINSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
      value: 'id'
    }
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: UninstallationStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: UninstallationStatusEnum.Draft
      }
    ]
  },
  {
    name: 'notes',
    label: 'Примітка',
    type: FilterType.Autocomplete,
    value: 'name',
    autocompleteConfig: {
      reducerName: UNINSTALLATION_NOTES_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.uninstallation.notesAutocomplete',
      optionsApiUrl: UNINSTALLATION_NOTES_AUTOCOMPLETE,
      value: 'name'
    }
  }
];

export const deleteWarningAppendix =
  'Все демонтоване обладнання буде повернене у моніторинг';
