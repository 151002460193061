import { createAsyncAction } from 'typesafe-actions';

interface RegisterInstallationConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

interface Payload {
  config: RegisterInstallationConfig;
}

export const registerInstallation = createAsyncAction(
  '@INSTALLATION/REGISTER_INSTALLATION_REQUEST',
  '@INSTALLATION/REGISTER_INSTALLATION_SUCCESS',
  '@INSTALLATION/REGISTER_INSTALLATION_FAILURE'
)<Payload, number, Error[]>();
