import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import { TransferForm } from '../../types';

export const initialState: TransferForm = {
  id: 0,
  documentNumber: '',
  dateTransfer: moment().format(DATEPICKER_DATE_FORMAT),
  performingEmployee: 0,
  acceptingEmployee: null,
  counteragentHolder: null,
  counteragentHolderAddress: '',
  counteragentTransfer: null,
  counteragentTransferAddress: '',
  notes: '',
  performingEmployeeName: ''
};

const transferStepReducer = createReducer<
  TransferForm,
  ActionType<typeof actions>
>(initialState).handleAction(actions.setFormValues, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default transferStepReducer;
