import { object, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import EquipmentStateLabel from 'src/components/Labels/EquipmentStateLabel';
import { Row } from 'src/components/Tables/types';
import { EQUIPMENT, BREAKDOWNS_TYPES } from 'src/config/assetUrls';

const equipmentChangeValue = (row: Row) => (
  <EquipmentStateLabel state={row.state} />
);

export const EMPTY_OPTION = 'Нічого не знайдено';

export const BREAKDOWNS_DATA_REDUCER_PATH = 'asset.breakdowns.create.data';

export const BREAKDOWNS_EQUIPMENT_SELECT_NAME = 'EQUIPMENT_SELECT_NAME';
export const BREAKDOWNS_EQUIPMENT_SELECT_PATH =
  'asset.breakdowns.equipmentSelect';

export const BREAKDOWNS_EQUIPMENT_SELECT_CONFIG = (
  counteragentId: number,
  vehicleId: number,
  equipmentTypeId: number | string,
  responsiblePersonId: number
) => ({
  reducerPath: BREAKDOWNS_EQUIPMENT_SELECT_PATH,
  reducerName: BREAKDOWNS_EQUIPMENT_SELECT_NAME,
  optionsApiUrl: `${EQUIPMENT}/installed/countragent/${counteragentId}${
    vehicleId ? `/vehicle/${vehicleId}` : ''
  }${equipmentTypeId ? `?equipmentTypeId=${equipmentTypeId}` : ''}${
    responsiblePersonId ? `&responsiblePersonId=${responsiblePersonId}` : ''
  }`
});

export const BREAKDOWNS_BREAKDOWN_SELECT_NAME = 'BREAKDOWN_SELECT_NAME';
export const BREAKDOWNS_BREAKDOWN_SELECT_PATH =
  'asset.breakdowns.breakdownSelect';

export const BREAKDOWNS_BREAKDOWN_SELECT_CONFIG = {
  reducerPath: BREAKDOWNS_BREAKDOWN_SELECT_PATH,
  reducerName: BREAKDOWNS_BREAKDOWN_SELECT_NAME,
  optionsApiUrl: BREAKDOWNS_TYPES
};

export const equipmentFormValidationScheme = object().shape({
  equipment: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .nullable(),
  breakdown: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .nullable()
});

export interface InitialFormValuesState {
  isOpen: boolean;
  isEditMode: boolean;
  values: {
    installationVehicleId?: number;
  };
}

export const initialFormState: InitialFormValuesState = {
  isOpen: false,
  isEditMode: false,
  values: {
    installationVehicleId: undefined
  }
};

export const columns = [
  {
    label: 'Штрихкод',
    value: 'barCode',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Назва',
    value: 'equipment',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Стан',
    value: 'equipmentStatusName',
    sortable: false,
    width: '20%',
    changeValue: equipmentChangeValue
  },
  {
    label: 'Несправність',
    value: 'breakdown',
    sortable: false,
    width: '20%'
  }
];
