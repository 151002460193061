import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { FormValues } from './types';

export const selectFormValues = (reducerPath: string) => (
  state: RootState
): FormValues => get(state, `ui.${reducerPath}`, {});

export const selectFormCreateData = (reducerPath: string) =>
  createSelector(selectFormValues(reducerPath), values => ({
    inspectionDate: values.inspectionDate,
    inspectorEmployeeId: values.inspectorEmployee?.id,
    counteragentId: values.counteragent?.id,
    equipmentGroupId: values.equipmentGroupId,
    notes: values.notes,
    vehicleId: values.vehicle?.id || null,
    responsiblePersonId: values.responsiblePerson?.id || null
  }));

export const selectFormEditData = (reducerPath: string) =>
  createSelector(selectFormValues(reducerPath), values => ({
    inspectionDate: values.inspectionDate,
    inspectorEmployeeId: values.inspectorEmployee?.id,
    notes: values.notes
  }));
