import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { browserHistory } from 'src/store';
import ScrollReset from 'src/components/ScrollReset';
import Notifier from 'src/components/Notifier';
import { useDispatch, useSelector } from 'react-redux';
import Routes from './routes';
import ConfirmDialog from './components/ConfrimDialog';
import * as appActions from './store/actions/app';
import { selectUser } from './store/selectors';
import { NavigationProvider } from './context/NavigationContext';

const jss = create({ plugins: [...jssPreset().plugins] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

const App = () => {
  useStyles();
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    // TODO: find solution to avoid this condition
    if (user) {
      dispatch(appActions.getMapConfiguration.request());
      dispatch(appActions.getUserEmployee.request());
    }
  }, [dispatch, user]);

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={browserHistory}>
              <NavigationProvider>
                <ScrollReset />
                <Routes />
                <Notifier />
                <ConfirmDialog />
              </NavigationProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
