import { Row } from 'src/components/Tables/types';
import { CARRIERS, ROUTES_CHANGES_LIST } from 'src/config/avlUrls';
import RoutesTenderStatusColumn from '../RoutesTenderStatusColumn';

export const columns = [
  {
    label: 'Дата зміни',
    value: 'modifiedAt',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Номер',
    value: 'number',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Назва маршруту',
    value: 'name',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Початок дії паспорта',
    value: 'effectiveDate',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Перевізник',
    value: 'counteragent',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Тариф, грн/км',
    value: 'tariffAmount',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Тендер',
    value: 'tenderStatus',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => (
      <RoutesTenderStatusColumn
        tenderStatus={row.tenderStatus}
        tenderStatusId={row.tenderStatusId}
      />
    )
  }
];

export const DISPATCHING_COUNTERAGENTS_NAME = 'DISPATCHING_COUNTERAGENTS';
export const DISPATCHING_COUNTERAGENTS_CONFIG = {
  prohibitDynamicLoad: true,
  optionsApiUrl: CARRIERS,
  reducerName: DISPATCHING_COUNTERAGENTS_NAME,
  reducerPath: 'avl.schedulesStatus.counteragents'
};

export const ROUTE_CHANGES_TABLE_PATH = 'avl.routes.routesChangesTable';
export const ROUTE_CHANGES_TABLE_NAME = 'ROUTE_CHANGES';

export const routesConfig = {
  reducerName: ROUTE_CHANGES_TABLE_NAME,
  reducerPath: ROUTE_CHANGES_TABLE_PATH,
  apiUrl: ROUTES_CHANGES_LIST,
  routeUrl: '/avl/routes/',
  rowActions: {},
  toolbarActions: {},
  isRowClickEnabled: false
};
