import {
  BREAKDOWNS_TYPES_AUTOCOMPLETE,
  EQUIPMENT_INSPECTOR_LIST,
  EQUIPMENT_MOBILE_LIST,
  EQUIPMENT_STATIONARY_LIST,
  STATES,
  TRACKING_ADDRESS_AUTOCOMPLETE,
  TRACKING_BARCODE_AUTOCOMPLETE,
  TRACKING_IMEI_AUTOCOMPLETE,
  TRACKING_INSPECTOR,
  TRACKING_MOBILE,
  TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
  TRACKING_SPARE_PARTS_AUTOCOMPLETE,
  TRACKING_STATIONARY,
  TRACKING_WAREHOUSE,
  WAREHOUSE_ADDRESS_AUTOCOMPLETE,
  WAREHOUSE_BARCODE_AUTOCOMPLETE,
  WAREHOUSE_EQUIPMENT_LIST,
  WAREHOUSE_IMEI_AUTOCOMPLETE,
  WAREHOUSE_SPARE_PARTS_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { VEHICLES_CATEGORIES } from 'src/config/avlUrls';
import { Row } from 'src/components/Tables/types';
import {
  FilterType,
  TableConfig
} from 'src/components/Tables/TableWithPagination/types';
import {
  BARCODE_AUTOCOMPLETE_NAME,
  BARCODE_AUTOCOMPLETE_PATH
} from 'src/views/asset/reports/const';
import { VEHICLES_AUTOCOMPLETE_CONFIG } from 'src/views/clearing/inspectionDocuments/InspectionDocumentsListView/const';
import { EquipmentGroupEnum, EquipmentTypeEnum } from 'src/constants';
import { formatDate } from 'src/components/Tables/TableWithPagination/utils';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import StateTypeColumn from '../../states/StatesListView/StateTypeColumn';
import { createCounteragentFilter } from '../../const';

export enum TrackingTabsValues {
  Mobile = 1,
  Stationary = 2,
  Inspector = 3,
  Warehouse = 4
}

const barcodeColumn = {
  label: 'Штрихкод',
  value: 'barcode',
  sortable: true,
  width: '10%'
};

const equipmentNameColumn = {
  label: 'Назва',
  value: 'equipmentName',
  sortable: true,
  width: '10%'
};

const modelColumn = {
  label: 'Модель',
  value: 'productType',
  sortable: true,
  width: '10%'
};

const manufacturerColumn = {
  label: 'Виробник',
  value: 'manufacturer',
  sortable: true,
  width: '10%'
};

const imeiColumn = {
  label: 'IMEI ',
  value: 'imei',
  sortable: true,
  width: '10%'
};

const counteragentColumn = {
  label: 'Підрозділ',
  value: 'counteragent',
  sortable: true,
  width: '10%'
};

const equipmentStateColumn = {
  label: 'Стан',
  value: 'equipmentStateId',
  sortable: true,
  width: '10%',
  changeValue: (row: Row) => (
    <StateTypeColumn id={row.equipmentStateId} name={row.equipmentStateName} />
  ),
  formatValue: (row: Row) => row.equipmentStateName
};

const installationDateColumn = {
  label: 'Дата монтажу',
  value: 'installationDate',
  sortable: true,
  width: '10%',
  formatValue: (row: Row) => formatDate(row.installationDate)
};

const lastInspectionDateColumn = {
  label: 'Дата останньої інспекції',
  value: 'lastInspectionDate',
  sortable: true,
  width: '10%',
  formatValue: (row: Row) => formatDate(row.lastInspectionDate)
};

const addressColumn = {
  label: 'Адреса',
  value: 'address',
  sortable: true,
  width: '10%'
};

const breakdownsColumn = {
  label: 'Несправності',
  value: 'breakdowns',
  sortable: true,
  width: '10%',
  changeValue: (row: Row) => <TextWithEllipsis text={row.breakdowns} />
};

const sparePartsColumn = {
  label: 'Запчастини',
  value: 'spareParts',
  sortable: true,
  width: '10%',
  changeValue: (row: Row) => <TextWithEllipsis text={row.spareParts} />
};

const responsibleEmployeeColumn = {
  label: 'Відповідальна особа',
  value: 'responsibleEmployee',
  sortable: true,
  width: '10%'
};

export const mobileColumns = [
  barcodeColumn,
  equipmentNameColumn,
  modelColumn,
  manufacturerColumn,
  imeiColumn,
  sparePartsColumn,
  counteragentColumn,
  {
    label: 'Тип ТЗ',
    value: 'vehicleType',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Реєстраційний номер ТЗ',
    value: 'licensePlate',
    sortable: true,
    width: '10%'
  },
  equipmentStateColumn,
  breakdownsColumn,
  installationDateColumn,
  lastInspectionDateColumn
];

export const stationaryColumns = [
  barcodeColumn,
  equipmentNameColumn,
  modelColumn,
  manufacturerColumn,
  imeiColumn,
  sparePartsColumn,
  counteragentColumn,
  addressColumn,
  responsibleEmployeeColumn,
  equipmentStateColumn,
  breakdownsColumn,
  installationDateColumn,
  lastInspectionDateColumn
];

export const inspectorColumns = [
  barcodeColumn,
  equipmentNameColumn,
  modelColumn,
  manufacturerColumn,
  imeiColumn,
  sparePartsColumn,
  counteragentColumn,
  addressColumn,
  responsibleEmployeeColumn,
  equipmentStateColumn,
  breakdownsColumn,
  installationDateColumn,
  lastInspectionDateColumn
];

export const warehouseColumns = [
  barcodeColumn,
  equipmentNameColumn,
  modelColumn,
  manufacturerColumn,
  imeiColumn,
  sparePartsColumn,
  counteragentColumn,
  addressColumn,
  equipmentStateColumn,
  breakdownsColumn,
  {
    label: 'Дата',
    value: 'inboundDate',
    sortable: true,
    width: '10%',
    formatValue: (row: Row) => formatDate(row.inboundDate)
  }
];

export const TRACKING_MOBILE_TABLE_PATH = 'asset.tracking.mobileTable';
export const TRACKING_MOBILE_TABLE_NAME = 'TRACKING_MOBILE';

export const TRACKING_STATIONARY_TABLE_PATH = 'asset.tracking.stationaryTable';
export const TRACKING_STATIONARY_TABLE_NAME = 'TRACKING_STATIONARY';

export const TRACKING_INSPECTOR_TABLE_PATH = 'asset.tracking.inspectorTable';
export const TRACKING_INSPECTOR_TABLE_NAME = 'TRACKING_INSPECTOR';

export const TRACKING_WAREHOUSE_TABLE_PATH = 'asset.tracking.warehouseTable';
export const TRACKING_WAREHOUSE_TABLE_NAME = 'TRACKING_WAREHOUSE';

const tableConfig = {
  routeUrl: '/asset/uninstallation/',
  toolbarActions: {},
  isRowClickEnabled: true
};

const counteragentFilter = createCounteragentFilter({ width: 240 });

// equipmentState filter

const equipmentStateFilter = {
  name: 'equipmentStateId',
  label: 'Стан',
  optionsURL: STATES,
  width: 240
};

// equipmentName filter

const getEquipmentNameFilter = (optionsURL: string) => ({
  name: 'equipmentId',
  label: 'Назва',
  optionsURL,
  value: 'id',
  width: 240
});

// model filter

const getModelFilter = (optionsURL: string) => ({
  name: 'ProductType',
  label: 'Модель',
  optionsURL,
  value: 'model',
  selectConfig: {
    IsUnique: true,
    responseFieldName: 'model'
  },
  width: 240
});

const mobileModelFilter = getModelFilter(EQUIPMENT_MOBILE_LIST);
const stationaryModelFilter = getModelFilter(EQUIPMENT_STATIONARY_LIST);
const inspectorModelFilter = getModelFilter(EQUIPMENT_INSPECTOR_LIST);

// manufacturer filter

const getManufacturerFilter = (optionsURL: string) => ({
  name: 'manufacturer',
  label: 'Виробник',
  optionsURL,
  value: 'manufacturer',
  selectConfig: {
    IsUnique: true,
    responseFieldName: 'manufacturer'
  },
  width: 240
});

// barcode filter

const createBarcodeFilter = (optionsApiUrl: string) => ({
  name: 'barcode',
  value: 'name',
  label: 'Штрихкод',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: BARCODE_AUTOCOMPLETE_NAME,
    reducerPath: BARCODE_AUTOCOMPLETE_PATH,
    optionsApiUrl
  },
  width: 240
});

const getBarcodeFilter = (equipmentType: EquipmentTypeEnum) =>
  createBarcodeFilter(
    `${TRACKING_BARCODE_AUTOCOMPLETE}?equipmentGroupIds=${equipmentType}`
  );

const warehouseBarcodeFilter = createBarcodeFilter(
  WAREHOUSE_BARCODE_AUTOCOMPLETE
);

// imei filter

export const TRACKING_IMEI_AUTOCOMPLETE_NAME = 'TRACKING_IMEI_AUTOCOMPLETE';
export const TRACKING_IMEI_AUTOCOMPLETE_PATH =
  'asset.tracking.imeiAutocomplete';

const getImeiFilter = (optionsApiUrl: string) => ({
  name: 'imei',
  value: 'name',
  label: 'IMEI',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: TRACKING_IMEI_AUTOCOMPLETE_NAME,
    reducerPath: TRACKING_IMEI_AUTOCOMPLETE_PATH,
    optionsApiUrl
  },
  width: 240
});

const mobileImeiFilter = getImeiFilter(
  `${TRACKING_IMEI_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Mobile}`
);
const stationaryImeiFilter = getImeiFilter(
  `${TRACKING_IMEI_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Stationary}`
);
const inspectorImeiFilter = getImeiFilter(
  `${TRACKING_IMEI_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Inspector}`
);
const warehouseImeiFilter = getImeiFilter(WAREHOUSE_IMEI_AUTOCOMPLETE);

// spareParts filter

export const TRACKING_SPARE_PARTS_AUTOCOMPLETE_NAME =
  'TRACKING_SPARE_PARTS_AUTOCOMPLETE';
export const TRACKING_SPARE_PARTS_AUTOCOMPLETE_PATH =
  'asset.tracking.sparePartsAutocomplete';

const getSparePartsFilter = (optionsApiUrl: string) => ({
  name: 'spareParts',
  value: 'name',
  label: 'Запчастини',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: TRACKING_SPARE_PARTS_AUTOCOMPLETE_NAME,
    reducerPath: TRACKING_SPARE_PARTS_AUTOCOMPLETE_PATH,
    optionsApiUrl
  },
  width: 240
});

const mobileSparePartsFilter = getSparePartsFilter(
  `${TRACKING_SPARE_PARTS_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Mobile}`
);
const stationarySparePartsFilter = getSparePartsFilter(
  `${TRACKING_SPARE_PARTS_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Stationary}`
);
const inspectorSparePartsFilter = getSparePartsFilter(
  `${TRACKING_SPARE_PARTS_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Inspector}`
);
const warehouseSparePartsFilter = getSparePartsFilter(
  WAREHOUSE_SPARE_PARTS_AUTOCOMPLETE
);

// address filter

export const TRACKING_ADDRESS_AUTOCOMPLETE_NAME =
  'TRACKING_ADDRESS_AUTOCOMPLETE';
export const TRACKING_ADDRESS_AUTOCOMPLETE_PATH =
  'asset.tracking.addressAutocomplete';

const getAddressFilter = (optionsApiUrl: string) => ({
  name: 'address',
  value: 'name',
  label: 'Адреса',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: TRACKING_ADDRESS_AUTOCOMPLETE_NAME,
    reducerPath: TRACKING_ADDRESS_AUTOCOMPLETE_PATH,
    optionsApiUrl
  },
  width: 240
});

const stationaryAddressFilter = getAddressFilter(
  `${TRACKING_ADDRESS_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Stationary}`
);
const inspectorAddressFilter = getAddressFilter(
  `${TRACKING_ADDRESS_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Inspector}`
);
const warehouseAddressFilter = getAddressFilter(WAREHOUSE_ADDRESS_AUTOCOMPLETE);

// responsibleEmployee filter

export const TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME =
  'TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE';
export const TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_PATH =
  'asset.tracking.responsibleEmployeeAutocomplete';

const getResponsibleEmployeeFilter = (optionsApiUrl: string) => ({
  name: 'responsibleEmployee',
  value: 'name',
  label: 'Відповідальна особа',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
    reducerPath: TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_PATH,
    optionsApiUrl
  },
  width: 240
});

const stationaryResponsibleEmployeeFilter = getResponsibleEmployeeFilter(
  `${TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Stationary}`
);
const inspectorResponsibleEmployeeFilter = getResponsibleEmployeeFilter(
  `${TRACKING_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE}?equipmentGroupIds=${EquipmentTypeEnum.Inspector}`
);

// breakdowns filter

export const TRACKING_BREAKDOWNS_AUTOCOMPLETE_NAME =
  'TRACKING_BREAKDOWNS_AUTOCOMPLETE';
export const TRACKING_BREAKDOWNS_AUTOCOMPLETE_PATH =
  'asset.tracking.breakdownsAutocomplete';

const breakdownsFilter = {
  name: 'breakdowns',
  value: 'name',
  label: 'Несправності',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: TRACKING_BREAKDOWNS_AUTOCOMPLETE_NAME,
    reducerPath: TRACKING_BREAKDOWNS_AUTOCOMPLETE_PATH,
    optionsApiUrl: BREAKDOWNS_TYPES_AUTOCOMPLETE
  },
  width: 240
};

export const mobileTableConfig = {
  reducerName: TRACKING_MOBILE_TABLE_NAME,
  reducerPath: TRACKING_MOBILE_TABLE_PATH,
  apiUrl: TRACKING_MOBILE,
  filters: [
    getBarcodeFilter(EquipmentTypeEnum.Mobile),
    getEquipmentNameFilter(EQUIPMENT_MOBILE_LIST),
    mobileModelFilter,
    getManufacturerFilter(EQUIPMENT_MOBILE_LIST),
    mobileImeiFilter,
    mobileSparePartsFilter,
    counteragentFilter,
    {
      name: 'vehicleType',
      label: 'Тип ТЗ',
      optionsURL: VEHICLES_CATEGORIES,
      value: 'name',
      width: 240
    },
    {
      name: 'licensePlate',
      label: 'Реєстраційний номер ТЗ',
      type: FilterType.Autocomplete,
      autocompleteConfig: VEHICLES_AUTOCOMPLETE_CONFIG,
      value: 'name',
      width: 240
    },
    equipmentStateFilter,
    breakdownsFilter
  ],
  ...tableConfig,
  isRowClickEnabled: false
} as TableConfig;

export const stationaryTableConfig = {
  reducerName: TRACKING_STATIONARY_TABLE_NAME,
  reducerPath: TRACKING_STATIONARY_TABLE_PATH,
  apiUrl: TRACKING_STATIONARY,
  filters: [
    getBarcodeFilter(EquipmentTypeEnum.Stationary),
    getEquipmentNameFilter(EQUIPMENT_STATIONARY_LIST),
    stationaryModelFilter,
    getManufacturerFilter(EQUIPMENT_STATIONARY_LIST),
    stationaryImeiFilter,
    stationarySparePartsFilter,
    counteragentFilter,
    stationaryAddressFilter,
    stationaryResponsibleEmployeeFilter,
    equipmentStateFilter,
    breakdownsFilter
  ],
  ...tableConfig,
  isRowClickEnabled: false
} as TableConfig;

export const inspectorTableConfig = {
  reducerName: TRACKING_INSPECTOR_TABLE_NAME,
  reducerPath: TRACKING_INSPECTOR_TABLE_PATH,
  apiUrl: TRACKING_INSPECTOR,
  filters: [
    getBarcodeFilter(EquipmentTypeEnum.Inspector),
    getEquipmentNameFilter(EQUIPMENT_INSPECTOR_LIST),
    inspectorModelFilter,
    getManufacturerFilter(EQUIPMENT_INSPECTOR_LIST),
    inspectorImeiFilter,
    inspectorSparePartsFilter,
    counteragentFilter,
    inspectorAddressFilter,
    inspectorResponsibleEmployeeFilter,
    equipmentStateFilter,
    breakdownsFilter
  ],
  ...tableConfig,
  isRowClickEnabled: false
} as TableConfig;

export const getWarehouseTableConfig = (counteragentTypeId?: number) =>
  ({
    reducerName: TRACKING_WAREHOUSE_TABLE_NAME,
    reducerPath: TRACKING_WAREHOUSE_TABLE_PATH,
    apiUrl: TRACKING_WAREHOUSE,
    filters: [
      warehouseBarcodeFilter,
      getEquipmentNameFilter(WAREHOUSE_EQUIPMENT_LIST),
      {
        name: 'ProductType',
        label: 'Модель',
        optionsURL: WAREHOUSE_EQUIPMENT_LIST,
        value: 'productType',
        selectConfig: {
          IsUnique: true,
          responseFieldName: 'productType'
        },
        width: 240
      },
      getManufacturerFilter(WAREHOUSE_EQUIPMENT_LIST),
      warehouseImeiFilter,
      warehouseSparePartsFilter,
      createCounteragentFilter({ counteragentTypeId, width: 240 }),
      warehouseAddressFilter,
      equipmentStateFilter,
      breakdownsFilter
    ],
    ...tableConfig,
    isRowClickEnabled: false
  } as TableConfig);

export const trackingDownloadConfigByTab = {
  [TrackingTabsValues.Mobile]: {
    name: EquipmentGroupEnum.Mobile,
    title: EquipmentGroupEnum.Mobile,
    config: mobileTableConfig,
    columns: mobileColumns
  },
  [TrackingTabsValues.Stationary]: {
    name: EquipmentGroupEnum.Stationary,
    title: EquipmentGroupEnum.Stationary,
    config: stationaryTableConfig,
    columns: stationaryColumns
  },
  [TrackingTabsValues.Inspector]: {
    name: EquipmentGroupEnum.Inspector,
    title: EquipmentGroupEnum.Inspector,
    config: inspectorTableConfig,
    columns: inspectorColumns
  },
  [TrackingTabsValues.Warehouse]: {
    name: EquipmentGroupEnum.Warehouse,
    title: EquipmentGroupEnum.Warehouse,
    config: getWarehouseTableConfig(),
    columns: warehouseColumns
  }
};
