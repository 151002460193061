import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { EQUIPMENT, TRANSFERS } from 'src/config/assetUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import {
  addRowsLocally,
  deleteRowLocally
} from 'src/components/Tables/TableView/store/actions';
import { selectList } from 'src/components/Tables/TableWithPagination/store/selectors';
import { Row } from 'src/components/Tables/types';
import * as actions from './actions';
import { setLocalEquipmentsArray } from './utils';

export function* getEquipments({
  payload
}: ReturnType<typeof actions.getEquipments.request>): SagaIterator {
  const {
    barcode,
    editBarcode,
    tableConfig,
    counteragentId,
    counteragentName
  } = payload;

  const {
    data: { mobileEquipment, stationaryEquipment, inspectorEquipment }
  } = yield call(
    apiClient.get,
    `${EQUIPMENT}/barcode/${barcode}/${counteragentId}`
  );

  const equipment = [
    ...mobileEquipment,
    ...stationaryEquipment,
    ...inspectorEquipment
  ].map(row => ({ ...row, name: row.equipmentName, assetId: row.id }));

  if (equipment.length) {
    if (editBarcode?.length) {
      const equipments = yield select(selectList(tableConfig.reducerPath));
      const row = equipments.find((item: Row) => item.barcode === editBarcode);

      yield put(deleteRowLocally(tableConfig, { row }));
    }

    yield put(
      addRowsLocally(tableConfig, { rows: setLocalEquipmentsArray(equipment) })
    );
  } else {
    yield put(
      enqueueSnackbar({
        key: 'transfer_equipment_snackbar',
        options: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          variant: 'warning'
        },
        message: `Даного обладнання не знайдено на підрозділі ${counteragentName}`
      })
    );
  }

  yield put(actions.getEquipments.success());
}

export function* approveTransfer({
  payload: id
}: ReturnType<typeof actions.approveTransfer.request>): SagaIterator {
  yield call(apiClient.put, `${TRANSFERS}/${id}/approve`);
  yield put(actions.approveTransfer.success());
}

export default function* TransferEquipmentsStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEquipments.request),
      safe(getEquipments, actions.getEquipments.failure)
    ),
    takeEvery(
      getType(actions.approveTransfer.request),
      safe(approveTransfer, actions.approveTransfer.failure)
    )
  ]);
}
