import { FC } from 'react';

export interface VehicleIconProps {
  color: string;
  stroke?: string;
  speed?: number;
}

export const StandVehicleIcon = ({
  color,
  stroke
}: {
  color: string;
  stroke: string;
}) => (
  <svg width="26" height="27" viewBox="0 0 26 27">
    <g transform="translate(1 1.388)" fill="none" fillRule="evenodd">
      <circle
        stroke={stroke}
        strokeWidth="2"
        fill={color}
        fillRule="nonzero"
        cx="12"
        cy="12"
        r="12"
      />
      <rect fill="#FFF" x="8" y="8" width="8" height="8" rx="1" />
    </g>
  </svg>
);

const VehicleIcon: FC<VehicleIconProps> = ({
  speed,
  color,
  stroke = '#2A64C5'
}) => {
  if (!speed) {
    return <StandVehicleIcon color={color} stroke={stroke} />;
  }

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" transform="rotate(-90)">
      <defs>
        <path
          id="tczrowzxga"
          d="M8 1.667L3 13.86 3.473 14.333 8 12.333 12.527 14.333 13 13.86z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-86 -182) translate(-.041 93.858) translate(7.345 46.542) rotate(71 24.638 97.131)">
                  <circle
                    cx="15.253"
                    cy="15.253"
                    r="12"
                    fill={color}
                    stroke={stroke}
                    strokeWidth="2"
                    transform="rotate(19 15.253 15.253)"
                  />
                  <g transform="rotate(19 -7.057 33.798)">
                    <mask id="5qxpa3qk9b" fill="#fff">
                      <use xlinkHref="#tczrowzxga" />
                    </mask>
                    <g fill="#FFF" mask="url(#5qxpa3qk9b)">
                      <path d="M0 0H16V16H0z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VehicleIcon;
